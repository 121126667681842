import { Billboard, SEO } from '@renderbus/common/components'
import React, { useRef } from 'react'
import {
  RC2025Banner,
  RC2025Header,
  RC2025Introduction,
  RC2025Prize,
  RC2025Time,
  RC2025Group,
  RC2025SpecialAward,
  RC2025Judge,
  RC2025Content,
  RC2025Standard,
  RC2025Support,
  RC2025Illustrate,
  RC2025About,
  RC2025Partner,
  RC2025Navigation,
  RC2025RecordKeep,
} from '../molecules/rc2025'
import { Content, RC2025Style } from './rc2025.atom'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  query {
    contentBg: file(relativePath: { eq: "rc2025/content-bg.webp" }) {
      ...fluidImage
    }
    contentBg2k: file(relativePath: { eq: "rc2025/content-bg-2k.webp" }) {
      ...fluidImage
    }
  }
`

function RC2025() {
  const { contentBg, contentBg2k } = useStaticQuery(query)
  const contentRef = useRef(null)
  const illustrateRef = useRef(null)

  return (
    <>
      <RC2025Style />
      <SEO
        title='第四届瑞云3D渲染动画创作大赛"新途" -官网报名入口-瑞云渲染'
        keywords='第四届瑞云渲染大赛,瑞云3D渲染动画创作大赛,3D渲染大赛,cg动画大赛,动画创作大赛'
        description='第四届瑞云3D渲染动画大赛“新途”，简称：瑞云渲染大赛，作为国内知名的3D动画艺术赛事，现正火热报名中。3D艺术爱好者可通过Renderbus瑞云渲染官网参与，争夺丰厚奖金，展现你的3D动画艺术才华。抓住这个机会，让你的创意在动画界闪耀，成为新一代的动画力量。'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/04/share-img.png'
        sharePostSlug='rc2025.html'
      />
      <RC2025Header contentRef={contentRef} illustrateRef={illustrateRef} />
      <RC2025Banner />
      <Content ref={contentRef}>
        <RC2025Navigation contentRef={contentRef} />
        <RC2025Introduction />
        <RC2025Time />
        <RC2025Group />
        <RC2025Prize />
        <RC2025SpecialAward />
        <RC2025Judge />
        <RC2025Content />
        <RC2025Standard />
        <RC2025Support />
        <RC2025Illustrate ref={illustrateRef} />
        <RC2025About />
        <RC2025Partner />
        <RC2025RecordKeep />
        <Billboard
          fluid={[
            contentBg.childImageSharp.fluid,
            {
              ...contentBg2k.childImageSharp.fluid,
              media: `(min-width: 1921px)`,
            },
          ]}
        />
      </Content>
    </>
  )
}

export default RC2025
