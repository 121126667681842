import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleIllustrate from '../../../images/rc2025/title-illustrate.webp'
import { Answer, Box, BoxOl, Content, QADoc, QAWrapper, Question, Tab, TabWrapper } from './atoms'

const tabs = ['参赛须知', '作品提交内容', '常见问题']

const notice = [
  '本次大赛不限渲染方式，不限渲染器；',
  '参赛请先填写报名表，并下载大赛资料，按照官方要求进行创作；',
  '成功报名后，参赛者会收到参赛邮件，邮件内将提供技术支持资产获取方式，参赛者可提前注册好平台账号，按需免费使用；',
  '发布作品：作品发布至B站抖音等视频平台，视频标题：“作品名| 第四届瑞云渲染大赛”，并在视频简介中进行作品描述，手动标签#瑞云3D渲染动画创作大赛##第四届瑞云渲染大赛#关键词，评论区@官号“Renderbus瑞云渲染”；',
  '提交参赛：需要按照参赛邮件内的要求提交作品，作者名务必要与报名时填写的姓名一致；',
  '诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任；',
]

const workContent = [
  <>
    本次参赛作品可使用任何3D软件进行创作，请以分辨率1920*1080或3840*2160尺寸，每秒24帧画面进行渲染，总长7秒整（总共
    <span>24fps x 7s = 168</span>帧）；
  </>,
  '画面中应只含有作品本身画面，注意不要出现不相干的水印；',
  '除剧情需要外，不要做黑场、白场、渐入；',
  '作品可包含音效，但不要使用背景音乐，我们后期会进行统一配乐；',
  '最终提交的作品必须是使用编码H.264输出的mp4格式的视频，大小100M以内；',
]

const qas = [
  {
    question: '问题1：参赛需要收费吗？ 报名必须用真名吗？',
    answer: '答：参赛为免费，报名即可参与；报名姓名不做真名要求，但是需要始终保持一致',
  },
  {
    question: '问题2：报名信息填写错误，是否可以更改？',
    answer:
      '答：报名邮箱仅可填写一次，信息错误无法在网站直接更改，无法通过重复报名覆盖修改信息，请仔细检查确认好信息无误再填写报名表，如出现信息错误请更换邮箱重新报名',
  },
  {
    question: '问题3：一个人可以提交多少个作品？可以组队吗？',
    answer:
      '答：每人只允许提交一个作品，本次比赛主要为个人艺术家全流程比赛，如团队参赛成员不得超过3人，且需要在作品描述中说明组队分工明细',
  },
  {
    question: '问题4：一个人可以获得多个奖项吗？',
    answer: '答：可以，冠亚季、优秀奖奖项与人气奖/特别奖并不冲突',
  },
  {
    question: '问题5：可以自由K动画吗？',
    answer: '答：可以，请保持始末角色动作、位置与模板一致',
  },
  {
    question: '问题6：如何提交作品？',
    answer:
      '答：参赛请务必先报名，报名成功后将收到参赛邮件，按照邮件内的要求提交作品即可。作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。2025/03/09 23:59之前都可以提交，仅一次上传覆盖机会，我们将以最新作品为准',
  },
  {
    question: '问题7：比赛期间，我必须将参赛作品发布B站、抖音、站酷、小红书等平台吗？',
    answer:
      '答：必须要发布的平台为B站抖音，发布时请在作品内标明该作品为“作品名| 第四届瑞云渲染大赛”，打好相关标签方便检索，并@瑞云官方账号“Renderbus瑞云渲染”。其他平台也建议发布，因为有利于参与人气奖的“网络热度”评选，如选手不发也不做强制',
  },
  {
    question: '问题8：投稿后，社媒平台是否可以删除作品？',
    answer:
      '答：不可以，报名后不支持删除作品。部分社媒如B站有稿件修改替换功能，如想优化可以再替换，请确认好以后再上传。另外，请保存好工程文件，后期可能会进行作品复核',
  },
  {
    question: '问题9：参赛作品是否可以带个人水印 /logo ?',
    answer:
      '答：为保证评审的公平公正性，上传到官方通道的作品不可以含有个人信息的特征标记，但是上传在视频社媒平台的作品片尾可以带上赛事的KV',
  },
  {
    question: '问题10：其他大赛的投稿作品是否可以参加本次大赛?',
    answer:
      '答：可以，但需避免侵犯其他主办方的权益，另外，不可以直接用前3届瑞云渲染大赛作品直接参赛',
  },
  {
    question: '问题11：我拿网上的概念设计图做成3D的参赛算侵权吗？大赛允许吗?',
    answer:
      '答：大赛允许，但是需要参赛者标明创意来源且获得原作者授权，原创分会被酌情扣除，不建议这么做',
  },
  {
    question: '问题12：我渲染完发现不满意，可以重新领券渲染吗？',
    answer:
      '答：每位选手仅一次免费云渲染权益，请确认好终版工程，再进行渲染哦，过程中遇到平台相关操作问题，可直接咨询站内客服，24h在线',
  },
  {
    question: '问题13：最佳人气奖将如何评选？',
    answer:
      '答：大赛资料包中会对人气奖评选做出明确解释，入围作品公布及投票的具体安排，请关注“Renderbus瑞云渲染”微信公众号',
  },
  {
    question: '问题14：获奖后将如何通知？如何联系大赛组委会',
    answer:
      '答：获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式，可联系邮箱：RRC@rayvision.com 或者添加微信：rayvision1，工作人员将尽快与您取得联系',
  },
  {
    question: '问题15：如何关注大赛的最新动态？',
    answer: '答：请关注微信公众号“Renderbus瑞云渲染”，我们将在公众号中及时公布大赛的最新动态',
  },
]

const contents = {
  [tabs[0]]: (
    <Box padding='50px 60px 0'>
      <BoxOl>
        {notice.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </BoxOl>
    </Box>
  ),
  [tabs[1]]: (
    <Box padding='50px 60px 0'>
      <BoxOl>
        {workContent.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </BoxOl>
    </Box>
  ),
  [tabs[2]]: (
    <Box padding='50px 30px 0 60px'>
      <QAWrapper>
        <QADoc>
          实时更新文档：
          <svg width='12.17578125' height='11.833984375' viewBox='0 0 12.17578125 11.833984375'>
            <g>
              <g>
                <path
                  d='M11.6416,7.0834L10.202,7.0834L10.202,0.891406C10.202,0.400586,9.80273,0,9.31055,0L2.8957,0C2.40488,0,2.0043,0.399219,2.0043,0.891406L2.0043,4.7332L0.53457,4.7332C0.239258,4.7332,0,4.96973,0,5.26094L0,11.3053C0,11.5965,0.239258,11.833,0.53457,11.833L11.6416,11.833C11.9369,11.833,12.1762,11.5965,12.1762,11.3053L12.1762,7.61113C12.1762,7.31992,11.9369,7.0834,11.6416,7.0834ZM2.96133,0.957031L9.24355,0.957031L9.24355,7.08203L6.22207,7.08203L5.45645,5.07363C5.37852,4.86992,5.17754,4.73184,4.95605,4.73184L2.96133,4.73184L2.96133,0.957031ZM6.32734,7.3582C6.32734,7.35684,6.32734,7.35684,6.32734,7.3582ZM11.2191,10.876L0.957031,10.876L0.957031,5.69023L4.66758,5.69023L5.4332,7.69863C5.51113,7.90234,5.71211,8.04043,5.93359,8.04043L11.2191,8.04043L11.2191,10.876Z'
                  fill='#FEBD2F'
                />
              </g>
              <g>
                <path
                  d='M8.0703125,2.460937875C8.0703125,2.197069875,7.8556625,1.982421875,7.5917925,1.982421875L4.6113285,1.982421875C4.3474605,1.982421875,4.1328125,2.197069875,4.1328125,2.460937875C4.1328125,2.7248048750000002,4.3474605,2.9394528749999997,4.6113285,2.9394528749999997L7.5917925,2.9394528749999997C7.8556625,2.9394528749999997,8.0703125,2.7248048750000002,8.0703125,2.460937875ZM7.5917925,4.098831875L6.962892500000001,4.098831875C6.6990225,4.098831875,6.4843725,4.313471874999999,6.4843725,4.577341875C6.4843725,4.841211875,6.6990225,5.055861875,6.962892500000001,5.055861875L7.5917925,5.055861875C7.8556625,5.055861875,8.0703125,4.841211875,8.0703125,4.577341875C8.0703125,4.313471874999999,7.8556625,4.098831875,7.5917925,4.098831875Z'
                  fill='#FEBD2F'
                />
              </g>
            </g>
          </svg>
          <a href='https://kdocs.cn/l/cli5mqkBxqZV' rel='nofollow' target='__blank'>
            第四届瑞云渲染大赛常见问题解答
          </a>
        </QADoc>
        {qas.map((item, index) => (
          <Fragment key={index}>
            <Question>{item.question}</Question>
            <Answer>{item.answer}</Answer>
          </Fragment>
        ))}
      </QAWrapper>
    </Box>
  ),
}

export const RC2025Illustrate = forwardRef((_, ref) => {
  const [active, setActive] = useState(tabs[0])

  useImperativeHandle(ref, () => ({
    setActiveQA: () => setActive(tabs[2]),
  }))

  return (
    <CommonWrapper id='rc2025-illustrate'>
      <CommonTitle src={titleIllustrate} marginTopMb={120} alt='设计说明' />
      <Content>
        <TabWrapper>
          {tabs.map((item) => (
            <Tab key={item} active={active === item} onClick={() => setActive(item)}>
              <span>{item}</span>
            </Tab>
          ))}
        </TabWrapper>
        {contents[active]}
      </Content>
    </CommonWrapper>
  )
})
