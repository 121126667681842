import React from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleSupport from '../../../images/rc2025/title-support.webp'
import logoRenderbus from '../../../images/rc2025/logo-renderbus.webp'
import logoQingjiaoyun from '../../../images/rc2025/logo-qingjiaoyun.webp'
import logoStyle3d from '../../../images/rc2025/logo-style3d.webp'
import logoDeemos from '../../../images/rc2025/logo-deemos.webp'
import { BottomButton, BottomWrapper, Desc, Logo, Support, SupportWrapper, Tip } from './atoms'
import { mbSize } from '@renderbus/common/theme'

const KnowArrow = () => (
  <svg
    width='8.573201179504395'
    height='7.278316020965576'
    viewBox='0 0 8.573201179504395 7.278316020965576'
  >
    <g transform='matrix(0,-1,1,0,-7.278316020965576,7.278316020965576)'>
      <path
        d='M3.16353,7.754898020965576L3.16353,14.156836020965576L0.831578,11.636136020965576C0.653552,11.439306020965576,0.34872,11.425446020965577,0.153503,11.605316020965576C-0.0417128,11.785186020965575,-0.0521419,12.089506020965576,0.130305,12.282266020965576L3.29017,15.698666020965575C3.47932,15.902466020965576,3.80229,15.902466020965576,3.99144,15.698666020965575L7.15131,12.282266020965576C7.3306,12.089166020965576,7.31862,11.787476020965576,7.12458,11.609146020965575C6.93086,11.430676020965576,6.62881,11.442756020965575,6.45003,11.636136020965576L4.11808,14.157156020965576L4.11808,7.754580020965577C4.11808,7.491547020965577,3.9044,7.278316020965576,3.64081,7.278316020965576C3.37721,7.278316020965576,3.16353,7.491547020965577,3.16353,7.754898020965576Z'
        fill='#444444'
        fillOpacity='1'
      />
    </g>
  </svg>
)

const DownloadArrow = () => (
  <svg
    width='7.27831506729126'
    height='8.59135627746582'
    viewBox='0 0 7.27831506729126 8.59135627746582'
  >
    <g>
      <path
        d='M3.16353,0.477591L3.16353,6.89309L0.831578,4.36704C0.653552,4.1698,0.34872,4.15592,0.153503,4.33616C-0.0417128,4.51641,-0.0521419,4.82138,0.130305,5.01454L3.29017,8.43818C3.47932,8.64242,3.80229,8.64242,3.99144,8.43818L7.1513,5.01454C7.3306,4.82104,7.31862,4.51871,7.12458,4.34C6.93086,4.16115,6.62881,4.17326,6.45003,4.36704L4.11808,6.89341L4.11808,0.477273C4.11808,0.213682,3.9044,0,3.64081,0C3.37721,0,3.16353,0.213682,3.16353,0.477591Z'
        fill='#444444'
        fillOpacity='1'
      />
    </g>
  </svg>
)

const items = [
  {
    logo: logoRenderbus,
    desc: '7X24h在线技术支持的一站式云渲染平台',
    leftText: (
      <>
        了解Renderbus
        <KnowArrow />
      </>
    ),
    leftUrl: 'https://www.renderbus.com/support-new/web-login/',
    rightText: (
      <>
        下载Renderbus
        <DownloadArrow />
      </>
    ),
    rightUrl: 'https://www.renderbus.com/download.html',
  },
  {
    logo: logoQingjiaoyun,
    desc: '设计师的高性能云端图形工作站',
    leftText: (
      <>
        了解青椒云
        <KnowArrow />
      </>
    ),
    leftUrl: 'https://www.qingjiaocloud.com/',
    rightText: (
      <>
        下载青椒云
        <DownloadArrow />
      </>
    ),
    rightUrl: 'https://www.qingjiaocloud.com/download/',
  },
  {
    logo: logoStyle3d,
    desc: '为CG行业而生，国产卓越的3D服装模拟工具',
    buttonFontSize: '10px',
    buttonFontSizeMb: 15,
    leftText: (
      <>
        下载Style3D Atelier
        <DownloadArrow />
      </>
    ),
    leftUrl: 'https://atelier.style3d.com/zh-CN',
    rightText: (
      <>
        下载Style3D Simulator
        <DownloadArrow />
      </>
    ),
    rightUrl: 'https://simulator.style3d.com/zh-CN',
  },
  {
    logo: logoDeemos,
    width: '106px',
    height: '24px',
    widthMb: 160,
    heightMb: 36,
    margin: '3px 0',
    marginMb: `${mbSize(4)} 0`,
    desc: '最接近Production-Ready的3D GenAI工具',
    leftText: (
      <>
        了解Rodin
        <KnowArrow />
      </>
    ),
    leftUrl: 'https://hyperhuman.deemos.com',
    rightText: (
      <>
        使用Rodin
        <DownloadArrow />
      </>
    ),
    rightUrl: 'https://hyperhuman.deemos.com/product/faq',
  },
]

export const RC2025Support = () => {
  return (
    <CommonWrapper>
      <CommonTitle src={titleSupport} alt='技术支持' />
      <SupportWrapper>
        {items.map((item, index) => (
          <Support key={index}>
            <Logo
              src={item.logo}
              width={item.width}
              height={item.height}
              margin={item.margin}
              widthMb={item.widthMb}
              heightMb={item.heightMb}
              marginMb={item.marginMb}
            />
            <Desc>{item.desc}</Desc>
            <BottomWrapper fontSize={item.buttonFontSize} fontSizeMb={item.buttonFontSizeMb}>
              <BottomButton href={item.leftUrl} target='__blank' rel='nofollow'>
                {item.leftText}
              </BottomButton>
              <BottomButton href={item.rightUrl} target='__blank' rel='nofollow'>
                {item.rightText}
              </BottomButton>
            </BottomWrapper>
          </Support>
        ))}
      </SupportWrapper>
      <Tip>
        参赛者可在参赛期间获得以上系列产品的技术支持，
        <a href='https://kdocs.cn/l/ctEhwFFc6lKh' target='__blank' rel='nofollow'>
          点击此处
        </a>
        查看赛事专属软件资源使用介绍
      </Tip>
    </CommonWrapper>
  )
}
