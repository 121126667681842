import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const GroupWrapper = styled.div`
  display: flex;
  gap: 120px;
  margin-top: 50px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    align-items: center;
    margin-top: ${mbSize(60)};
    gap: ${mbSize(60)};
  }
`

export const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 20px;
  text-shadow: 0px 0px 29px #f8c12b;
  img.line {
    width: 106px;
    height: 7px;
    margin: 10px 0 16px;
  }
  img.pic {
    width: 338px;
    height: 132px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    img.line {
      width: ${mbSize(160)};
      height: ${mbSize(10)};
      margin: ${mbSize(12)} 0 ${mbSize(30)};
    }
    img.pic {
      width: ${mbSize(500)};
      height: ${mbSize(200)};
    }
  }
`
