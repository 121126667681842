import React, { useRef, useState } from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import {
  MainJudgeAvatar,
  MainJudgeItem,
  MainJudgeWrapper,
  GroupLine,
  Subtitle,
  MainJudgeContent,
  MainJudgeName,
  MainJudgeIntroduce,
  MoreIcon,
  MoreWrapper,
  MoreName,
  MoreIntroduce,
  JudgeWrapper,
  JudgeItem,
  JudgeAvatar,
  JudgeName,
  JudgeIdentity,
  JudgeMore,
  JudgeMoreTitle,
  JudgeMoreIntroduce,
  JudgeIdentityMb,
  Decorate3,
  MoreWrapperMb,
  MoreHeaderMb,
  MoreContentMb,
} from './atoms'
import titleJudge from '../../../images/rc2025/title-judge.webp'
import groupLine from '../../../images/rc2025/group-line.webp'
import mainJudge1 from '../../../images/rc2025/main-judge1.webp'
import mainJudge2 from '../../../images/rc2025/main-judge2.webp'
import mainJudge3 from '../../../images/rc2025/main-judge3.webp'
import mainJudge4 from '../../../images/rc2025/main-judge4.webp'
import mainJudge5 from '../../../images/rc2025/main-judge5.webp'
import moreIcon from '../../../images/rc2025/more-icon.webp'
import judge02 from '../../../images/rc2025/judge02.webp'
import judge03 from '../../../images/rc2025/judge03.webp'
import judge04 from '../../../images/rc2025/judge04.webp'
import judge05 from '../../../images/rc2025/judge05.webp'
import judge06 from '../../../images/rc2025/judge06.webp'
import judge07 from '../../../images/rc2025/judge07.webp'
import judge08 from '../../../images/rc2025/judge08.webp'
import judge09 from '../../../images/rc2025/judge09.webp'
import judge10 from '../../../images/rc2025/judge10.webp'
import judge11 from '../../../images/rc2025/judge11.webp'
import judge12 from '../../../images/rc2025/judge12.webp'
import judge13 from '../../../images/rc2025/judge13.webp'
import judge14 from '../../../images/rc2025/judge14.webp'
// import judge15 from '../../../images/rc2025/judge15.webp'
import judge16 from '../../../images/rc2025/judge16.webp'
import judge17 from '../../../images/rc2025/judge17.webp'
import judge18 from '../../../images/rc2025/judge18.webp'
import judge19 from '../../../images/rc2025/judge19.webp'
import judge20 from '../../../images/rc2025/judge20.webp'
import decorate3 from '../../../images/rc2025/decorate3.webp'
import closeIcon from '../../../images/rc2025/close-icon.webp'
import { useClickOutside } from '@renderbus/common/hooks'

const mainJudges = [
  {
    avatar: mainJudge1,
    name: 'Anthony LaMolinara',
    introduce: '第77届奥斯卡最佳特效导演奖得主 、好莱坞电影大师',
    more: [
      '美国导演、编剧、视觉特效、动画师、画家、雕塑家和音乐家，第77届奥斯卡最佳特效导演奖得主 ，好莱坞电影大师，奥斯卡最佳外语片、最佳动画片、最佳动画短片和最佳短片奖评委',
      '代表作有：《蜘蛛侠》、《蜘蛛侠 2》、《透明人》、《玩具总动员》、《玩具总动员 3》、《精灵鼠小弟》、《You Can, You Up》、《Triangle》、《最终幻想》、《刀锋战士》、《霸王龙》等，以及《阿凡达》的前期开发，Michael Jackson的多部音乐片作品等',
    ],
  },
  {
    avatar: mainJudge5,
    name: '高枫',
    introduce: '资深视效总监、视效制片人',
    more: [
      '14年影视制作和项目管理经验，熟悉本土和好莱坞电影以及剧集的生产制作流程，继续保持行业深度学习的视效总监以及视效制片人，同时也致力于视效行业制片流程开发，曾就职于Double Negative (London)，PO，Pixomondo, Base FX等，现为独立视效制片人，制作总监，服务于博纳，腾讯，耀来等制片方。期间陆续参与上海电影节论坛，九天科幻项目制片大师班等课程。',
      '同时在过去的9年，独立运营影视视效行业公众号CGView，独立撰写业内项目案列分析，制作流程以及规范相关的文章，帮助行业推进规范流程，目前为《The Filmmaker’s Guide to Visual Effects》一书完成中文版翻译。',
    ],
  },
  {
    avatar: mainJudge2,
    name: '穆智飞',
    introduce:
      '概念艺术指导、制作设计师、动画视效指导、中国电影艺术学会CG艺术委员会主任、中央美术学院电影视效⼯作室专业导师',
  },
  {
    avatar: mainJudge3,
    name: '沈映',
    introduce: '欧特克传媒娱乐行业大中华区技术专家，有着丰富的行业技术背景，涉足三维动画行业多年',
  },
  {
    avatar: mainJudge4,
    name: '于朕',
    introduce:
      '中国美协数字艺术艺委会副主任，中国美院创新设计学院副院长, 教育部虚拟仿真教学创新实验室主任',
  },
]

const judges = [
  {
    avatar: judge02,
    name: '邓强',
    identity: ['西安美术学院影视动画系主任'],
    more: [
      '西安美术学院影视动画系主任、三维动画工作室负责人、教授、硕士研究生导师、设计学博士',
      'Autodesk M&E 三维动画教育专家(China)、Autodesk 3dsmax国际讲师、中国美术家协会会员、动画导演，研究方向：三维动画',
      '主要作品有《案板江湖》、《speedfire》、《城》、《十八重天》等',
    ],
  },
  {
    avatar: judge03,
    name: '董艳',
    identity: ['CG模型网CEO'],
    more: [
      'CG模型网CEO',
      '中央美术学院国际学院数字艺术研究中心副主任',
      '中国电影美术学会CG艺术专委会副秘书长',
      '湖南省动漫游戏协会CG数字艺术专委会主任',
    ],
  },
  {
    avatar: judge04,
    name: '段雯锴',
    identity: ['中国传媒大学动画与数字艺术学院副教授'],
    introduceFontSize: '9.8px',
    more: [
      '中国传媒大学动画与数字艺术学院教师、动画导演、动画创作人',
      '段雯锴于2012年创作动画短片《抢狮头》，该短片一举斩获包括中国动画美猴奖、东京国际动画节评委会特别奖等20余项殊荣',
      '作品《沉默之蓝：序》已在全世界超过40个动画电影节中获奖入围，包括具有奥斯卡和英国学院奖认证资质的洛杉矶国际短片电影节、巴西AnimaMundi国际动画节、罗马尼亚Animest国际动画节等',
    ],
  },
  {
    avatar: judge05,
    name: '高汉威',
    identity: ['韩国国立首尔大学', '设计学博士'],
    more: [
      '韩国国立首尔大学设计学博士、韩国设计协会会员，广州美术学院视觉艺术设计学院专任教师：多年从事数字美术、多媒体及数字娱乐方面的教学研究工作',
    ],
  },
  {
    avatar: judge06,
    name: '卢东彪',
    identity: ['腾讯游戏美术专家', '中央美术学院特聘教授'],
    isCombineMb: true,
    more: [
      '腾讯游戏美术专家、腾讯游戏 天美工作室群 Z1 Studio元宇宙项目组 美术负责人',
      '中央美术学院特聘教授、四川美术学院特邀专家、清华大学研究生院校外导师、中国电影美术学会CG专委会副主任、鲁迅美术学院传媒动画学院特邀硕士生导师、中央美术学院电影VEX工作室特邀教授',
    ],
  },
  {
    avatar: judge07,
    name: '骆駪駪',
    identity: ['北京电影学院影视技术系副教授'],
    more: [
      '北京电影学院影视技术系教师、中国电影电视技术学会先进影像专业委员会秘书长、光大集团青旅集团文旅智库专家顾问、国际沉浸娱乐协会（IMERSA）会员',
      '主要研究和设计集中在沉浸式数字影像在文化遗产中的应用，涉及球幕技术、虚拟现实技术、MR、XR、人机交互等领域',
    ],
  },
  {
    avatar: judge20,
    name: '彭程',
    identity: ['PBRMAX创始人兼CEO、中国科技艺术新生代领军人物'],
  },
  {
    avatar: judge08,
    name: '马佳',
    identity: ['北京相心文化艺术有限公司艺术总监, 点新闻AIGC实验室CG总监'],
    more: [
      '2008年开始电影美术与视效概念工作，至今已有十几年相关影视及游戏相关视觉CG的工作经验。主要负责视效和前期概念设计、分镜、on-set设计方案制定、视效总监、CG导演等。',
      '2014-2015年曾任火星时代教育影视概念专业资深讲师。参与电影《戏台》、《风流一代》、《一路长安》、《明日之镜》、《长津湖水门桥》、《决胜时刻》、《奇门遁甲》、《天气预爆》、《空天猎》、《万万没想到》等视觉概念与气氛图设计。',
      '参与电视剧《三体》、《天行健》、《斗罗大陆2》、《大宋宫词》、《终极笔记》、《千古玦尘》、《北灵少年志之大主宰》、《御赐小䵬作》、《遮天》、《龙岭迷窟》等专案视野概念设计、视野概念方案、分镜设计、预演设计。',
      '游戏项目：《傲视水浒》、《新蜀山》等。',
    ],
  },
  {
    avatar: judge09,
    name: '梦璇',
    identity: ['拾梦猫动漫联合创办人、北京顶点视觉科技有限公司专案总监'],
    more: [
      '多年广告监制及制作经验，服务汽车品牌50家+，车型车款100+。涵盖传统KV、视讯物料、及AR、XR等新兴视觉技术解决方案领域。',
      '担任院线电影、动画电影的视效制片，包括《流浪地球》《时间之子》等。',
      '并担任原创动漫IP《疾风竞速》执行制片人。',
    ],
  },
  {
    avatar: judge10,
    name: 'Rofix',
    identity: ['CG动画导演', '前腾讯主美'],
    isCombineMb: true,
    more: [
      'CG动画导演、前腾讯主美，毕业于UCLA（加州大学洛杉矶分校）和RISD（罗德岛设计学院）',
      '擅长VR动画（Quill），创作了首部完全在VR里制作的短片动画，曾受邀在Facebook讲座以及登上3D Artist杂志专栏',
    ],
  },
  {
    avatar: judge11,
    name: '孙略',
    identity: ['北京电影学院教授', '影视技术副主任'],
    isCombineMb: true,
    more: [
      '北京电影学院教授、影视技术副主任、硕士研究生导师',
      '长期从事数字影像领域的教学、研究与创作工作，代表性作品：《雪花工场》、《奇石工场》、《停顿的虚像》、《静止的电影》、《扭曲的时间》等',
    ],
  },
  {
    avatar: judge12,
    name: '史叶',
    identity: ['虚幻引擎授权导师'],
  },
  {
    avatar: judge13,
    name: '魏春明',
    identity: ['上海工艺美术学会数字艺术设计专业委员会副主任'],
    more: [
      '上海工艺美术学会数字艺术设计专业委员会副主任、上海国际时尚教育中心 SIFEC 新媒体学院特聘教师；第五届、第八届全国高校数字艺术设计大赛（NCDA）评委、学术委员会委员',
    ],
  },
  {
    avatar: judge14,
    name: '杨翰',
    identity: ['著名虚幻引擎电影人', '参与制作《金刚狼3》等'],
    more: [
      '虚幻引擎电影人，从电影特效行业转行到3A游戏，把电影叙事带入游戏制作里。油管up主，频道UnrealHan，发布虚幻引擎教程，电影制作心得和最新特效科技测评。曾入职过多家国外知名特效公司：METHODSTUDIOS，MPC，参与过多部好莱坞特效影片制作：《金刚狼3》（logan），《星际迷航：发现号》（Star trek：Discovery)，《海王》（Aquaman），《鲨滩》（The Shallows)',
    ],
  },
  {
    avatar: judge16,
    name: '余如莹',
    identity: ['Motion Designer\\Art Director'],
    more: [
      'Motion Designer\\Art Director',
      '从业10年，原工业设计专业，中国一线动态视觉设计师、美术指导，主要从事产品动画、概念影片以及其他三维视觉设计和导演 等工作，从制作到出品，得到了行业和品牌的认可',
      '曾监制参与《时光代理人》OP、Bilibili寻光系列后期总监及部分镜头制作、以及vivo、oppo、小米等多家品牌手机外观概念宣传片',
    ],
  },
  {
    avatar: judge17,
    name: '周立钧',
    identity: ['广州美术学院数字娱乐教研中心主任，博士，副教授，硕导'],
    more: [
      '周立均，广州美术学院数字娱乐教研中心主任，博士，副教授，硕导，2010广州亚运会吉祥物“乐羊羊”主设计师，NVIDIA Studio中国区特约设计师，游戏制作人，动画导演。中国图像图形学学会数码艺术专委会委员，广东省动漫艺术家协会理事，广东省文化学会青少年文艺专委会副主任，广州市非物质文化遗产保护工作专家委员，广州市海珠区创意文化顾问，广东省游戏产业协会特聘专家，广东省玩具协会特聘潮玩行业专家，广东省计算机学会航空航天科技应用专委会智库专家，中国游戏十强年度榜/游戏行业金口奖/金海豚/GGAC/米哈游/吉比特&雷霆游戏等游戏比赛评委。',
      '研究领域为游戏、动画、IP角色系统开发及数字文旅，专注于以数智科技驱动传统文化体验创新、数字娱乐艺术在非遗/传播/教育/文旅等领域的理论与应用研究。',
    ],
  },
  {
    avatar: judge18,
    name: '周罡',
    identity: ['湖北美术学院影视动画学院教师'],
    more: [
      '湖北美术学院影视动画学院教师',
      '个展:',
      '2011年 “寻找”个展 纽伦堡艺术学院展览厅 纽伦堡 德国',
      '2017年“CHINA CONNECTION I.” 斯图加特 德国',
      '2021年彼众空间 眼见为实 武汉',
    ],
  },
  {
    avatar: judge19,
    name: '只剩一瓶辣椒酱',
    identity: ['ACGGIT 和 AIGODLIKE 创始人', 'B站Blender专题创作者/开发者'],
    isCombineMb: true,
    more: [
      '是一个拥有超过 340k 粉丝专注于 Blender 内容制作和插件设计的博主，同时也是一个坚定的开源信仰者。',
      '在 Blender 知识科普方面，从 Blender2.79 开始，基于知识开源向公众提供了超过400集以上的教程《黑铁骑士Ⅰ》《黑铁骑士Ⅱ》以及其他 Blender 相关知识内容。',
      '在 Blender 工具开发方面，发起了 Github 开源组织 AIGODLIKE，主导了 Simple deform helper（简易形变助手）、LJ-Studio（万物有灵Studio）、Place helper（放置助手）、Gesture helper（手势助手）、Camera helper（相机助手）、Light helper（灯光助手）、Popoti align helper（对齐助手）、Ppi tools（ppi助手）等 Blender 工具的设计。',
      '在 AI 方面，主导开发了中国应用最广泛的 ComfyUI 翻译项目 AIGODLIKE-ComfyUI-Translation，同时最早将 Stable diffsuion webUI 和 ComfyUI 接入 Blender，使 Blender 成为了最早接入 AI 工作流的 DCC 软件。',
    ],
  },
]

export const RC2025Judge = () => {
  const [showMoreMb, setShowMoreMb] = useState(false)
  const [moreInfo, setMoreInfo] = useState()
  const [name, setName] = useState('')
  const moreMbRef = useRef(null)

  const handleClick = (more, name) => {
    if (more) {
      setMoreInfo(more)
      setName(name)
      setShowMoreMb(true)
    }
  }

  useClickOutside(moreMbRef, () => showMoreMb && setShowMoreMb(false), ['click'])

  return (
    <CommonWrapper id='rc2025-judge' paddingBottom='20px'>
      <CommonTitle src={titleJudge} alt='评委阵容' />
      <Subtitle>大赛主评委</Subtitle>
      <GroupLine src={groupLine} alt='' />
      <MainJudgeWrapper>
        {mainJudges.map((item, index) => (
          <MainJudgeItem key={index} onClick={() => handleClick(item.more, item.name)}>
            <MainJudgeAvatar src={item.avatar} alt='' />
            <MainJudgeContent>
              <MainJudgeName>{item.name}</MainJudgeName>
              <MainJudgeIntroduce>{item.introduce}</MainJudgeIntroduce>
              {item.more && <MoreIcon src={moreIcon} alt='more' />}
            </MainJudgeContent>
            {item.more && (
              <MoreWrapper>
                <MoreName>{item.name}</MoreName>
                <MoreIntroduce>
                  {item.more.map((moreItem, moreIndex) => (
                    <div key={moreIndex}>{moreItem}</div>
                  ))}
                </MoreIntroduce>
              </MoreWrapper>
            )}
          </MainJudgeItem>
        ))}
      </MainJudgeWrapper>
      <Subtitle>大赛评审团</Subtitle>
      <GroupLine src={groupLine} alt='' />
      <JudgeWrapper>
        {judges.map((item, index) => (
          <JudgeItem key={index} onClick={() => handleClick(item.more, item.name)}>
            {item.more && (
              <JudgeMore>
                <JudgeMoreTitle>{item.name}</JudgeMoreTitle>
                <JudgeMoreIntroduce>
                  {item.more.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </JudgeMoreIntroduce>
              </JudgeMore>
            )}
            <JudgeAvatar src={item.avatar} />
            <JudgeName>{item.name}</JudgeName>
            <JudgeIdentity width={item.width} fontSize={item.introduceFontSize}>
              {item.identity.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </JudgeIdentity>
            <JudgeIdentityMb>{item.identity.join(item.isCombineMb ? '、' : '')}</JudgeIdentityMb>
          </JudgeItem>
        ))}
        <Decorate3 src={decorate3} alt='decorate3' />
      </JudgeWrapper>
      <MoreWrapperMb ref={moreMbRef} show={showMoreMb}>
        <MoreHeaderMb>
          {name}
          <img src={closeIcon} alt='close' loading='lazy' onClick={() => setShowMoreMb(false)} />
        </MoreHeaderMb>
        <MoreContentMb>
          {moreInfo?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </MoreContentMb>
      </MoreWrapperMb>
    </CommonWrapper>
  )
}
