import styled from 'styled-components'
import contentButton from '../../../images/rc2025/content-button.webp'
import contentButtonHover from '../../../images/rc2025/content-button-hover.webp'
import videoBg from '../../../images/rc2025/video-bg.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  height: 887px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  margin-top: 120px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(0)};
    height: unset;
    .pc-bg {
      display: none;
    }
  }
`

export const Content = styled.div`
  display: flex;
  gap: 70px;
  align-items: center;
  margin-top: 60px;
  position: relative;
  .mb-bg {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(694)};
    height: ${mbSize(1180)};
    flex-direction: column-reverse;
    margin-top: ${mbSize(60)};
    gap: ${mbSize(60)};
    padding-bottom: ${mbSize(80)};
    .mb-bg {
      display: block;
    }
  }
`

export const LeftContent = styled.div`
  width: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    gap: ${mbSize(60)};
  }
`

export const ContentText = styled.ul`
  padding: 0 0 0 20px;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.1em;
  li {
    list-style: unset;
    color: #fff;
    margin-bottom: 24px;
  }
  li:last-child {
    margin-bottom: 0;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    width: ${mbSize(640)};
    margin-right: ${mbSize(40)};
    li {
      margin-bottom: ${mbSize(48)};
    }
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 227px;
  height: 60px;
  background: url(${contentButton});
  background-size: 100%;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  font-family: 'YouSheBiaoTiHei';
  cursor: pointer;
  a {
    font-size: 26px;
    background: linear-gradient(273deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 105%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    background: url(${contentButtonHover});
    background-size: 100%;
    a {
      background: linear-gradient(87deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 105%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(264)};
    height: ${mbSize(70)};
    a {
      font-size: ${mbSize(30)};
    }
  }
`

export const VideoWrapper = styled.div`
  position: relative;
  width: 560px;
  height: 347px;
  background: url(${videoBg});
  background-size: 100%;
  padding: 27px 21px 0;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 15px;
    left: 12px;
    right: 12px;
    bottom: 10px;
    box-shadow: 0px 0px 29px 0px #f8c12b;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(654)};
    height: ${mbSize(406)};
    padding: ${mbSize(32)} ${mbSize(24)} 0;
  }
`

export const Video = styled.video`
  width: 518px;
  height: 300px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(606)};
    height: ${mbSize(352)};
  }
`
