import React from 'react'

export const LeftIcon = () => (
  <svg version='1.1' width='21' height='35' viewBox='0 0 21 35'>
    <defs>
      <clipPath id='master_svg0_81_21838'>
        <rect x='21' y='0' width='21' height='35' rx='0' />
      </clipPath>
    </defs>
    <g transform='matrix(-1,0,0,1,42,0)' clipPath='url(#master_svg0_81_21838)'>
      <g transform='matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,8.150757849216461,-15.67766922712326)'>
        <path
          d='M43.560500000000005,4L43.560500000000005,24.559L47.560500000000005,24.559L47.560500000000005,0L23,0L23,4L43.560500000000005,4Z'
          fillRule='evenodd'
          fill='current'
          fillOpacity='1'
        />
      </g>
      <g transform='matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,16.514718770980835,-13.870057225227356)'>
        <path
          d='M31.64181,14L31.64355,20.64284L33.64355,20.642319999999998L33.64129,12L25,12L25,14L31.64181,14Z'
          fillRule='evenodd'
          fill='current'
          fillOpacity='1'
        />
      </g>
    </g>
  </svg>
)
