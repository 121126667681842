import styled from 'styled-components'
import supportBg from '../../../images/rc2025/support-bg.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const SupportWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 45px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    margin-top: ${mbSize(30)};
    gap: ${mbSize(48)};
  }
`

export const Support = styled.div`
  position: relative;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 285px;
  height: 180px;
  background: url(${supportBg});
  background-size: 100%;
  background-repeat: no-repeat;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(426)};
    height: ${mbSize(270)};
    padding-top: ${mbSize(30)};
  }
`

export const Logo = styled.img`
  width: ${({ width }) => width || '112px'};
  height: ${({ height }) => height || '30px'};
  margin: ${({ margin }) => margin || 'unset'};
  ${Media.lessThan(Media.small)} {
    width: ${({ widthMb }) => mbSize(widthMb || 168)};
    height: ${({ heightMb }) => mbSize(heightMb || 45)};
    margin: ${({ marginMb }) => marginMb || 'unset'};
  }
`

export const Desc = styled.div`
  font-size: 12px;
  color: #ffffff;
  margin-top: 12px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(18)};
    margin-top: ${mbSize(12)};
  }
`

export const BottomWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 81px;
  bottom: 0;
  left: 0;
  font-size: ${({ fontSize }) => fontSize || '12px'};
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(120)};
    font-size: ${({ fontSizeMb }) => mbSize(fontSizeMb) || mbSize(18)};
  }
`

export const BottomButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 50%;
  height: 100%;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  ${Media.lessThan(Media.small)} {
    gap: ${mbSize(6)};
    svg {
      width: ${mbSize(15)};
      height: ${mbSize(15)};
    }
  }
`

export const Tip = styled.div`
  margin-top: 30px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  a {
    color: #febd2f;
    text-decoration: underline;
  }
  &:hover {
    a {
      text-decoration: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(480)};
    font-size: ${mbSize(21)};
    line-height: ${mbSize(36)};
  }
`
