import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const TimeWrapper = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    flex-direction: column;
    gap: unset;
  }
`

export const Item = styled.div`
  position: relative;
  width: 250px;
  height: 50px;
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c0a17;
  z-index: 1;
  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(390)};
    height: ${mbSize(76)};
    font-size: ${mbSize(28)};
    margin: 0 0 ${mbSize(136)} 0;
    &:first-child {
      margin-top: ${mbSize(60)};
    }
    &:nth-child(3) {
      margin-bottom: ${mbSize(76)};
    }
  }
`

export const Time = styled.div`
  width: 296px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #313131;
  color: #fff;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    width: ${mbSize(444)};
    height: ${mbSize(60)};
    top: ${mbSize(92)};
  }
`

export const Line = styled.div`
  width: 1200px;
  height: 1px;
  background: #febd2f;
  position: absolute;
  top: 90px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -4px;
    width: 8px;
    height: 8px;
    background: #febd2f;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: -4px;
    width: 8px;
    height: 8px;
    background: #febd2f;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
