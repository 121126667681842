import styled from 'styled-components'
import mainJudgeContentBg from '../../../images/rc2025/main-judge-content-bg.webp'
import { mbSize, Media, slideUp } from '@renderbus/common/theme'

export const Subtitle = styled.div`
  font-size: 20px;
  color: #fff6dc;
  text-shadow: 0px 0px 29px #f8c12b;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
    margin-top: ${mbSize(60)};
    text-shadow: 0px 0px ${mbSize(42)} #f8c12b;
  }
`

export const GroupLine = styled.img`
  width: 106px;
  height: 7px;
  margin: 8px 0 20px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(160)};
    height: ${mbSize(10)};
    margin: ${mbSize(15)} 0 ${mbSize(32)};
  }
`

export const MainJudgeWrapper = styled.div`
  display: flex;
  gap: 30px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(586)};
    margin: 0 auto;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: ${mbSize(24)};
  }
`

export const MoreWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 4px;
  right: 4px;
  bottom: 5px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  padding: 12px 11px;
  opacity: 0;
  transition: all 0.3s;
  overflow: auto;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const MainJudgeItem = styled.div`
  width: 216px;
  height: 330px;
  box-sizing: border-box;
  border: 5px solid;
  border-image: linear-gradient(326deg, #f0feea -1%, #febd2f 20%, #26fdf8 69%, #ffffff 100%) 1;
  position: relative;
  padding: 8px 4px 5px;
  &:hover {
    ${MoreWrapper} {
      opacity: 1;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 4px;
    right: 4px;
    bottom: 5px;
    box-shadow: inset 0px -5px 63px 0px rgba(248, 193, 43, 0.48);
    z-index: 0;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(280)};
    height: ${mbSize(474)};
    border: ${mbSize(5)} solid;
    border-image: linear-gradient(326deg, #f0feea -1%, #febd2f 20%, #26fdf8 69%, #ffffff 100%) 1;
    &::before {
      content: '';
      position: absolute;
      top: ${mbSize(10)};
      left: ${mbSize(8)};
      right: ${mbSize(8)};
      bottom: ${mbSize(8)};
      box-shadow: inset 0px -5px 63px 0px rgba(248, 193, 43, 0.48);
      z-index: 0;
    }
  }
`

export const MainJudgeAvatar = styled.img`
  width: 100%;
  height: 222px;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(286)};
  }
`

export const MainJudgeContent = styled.div`
  width: 198px;
  height: 163px;
  position: absolute;
  bottom: 5px;
  background: url(${mainJudgeContentBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 26px 11px 0;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(256)};
    height: ${mbSize(260)};
    bottom: ${mbSize(8)};
    padding: ${mbSize(32)} ${mbSize(8)} 0;
  }
`

export const MainJudgeName = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #444444;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
  }
`

export const MainJudgeIntroduce = styled.div`
  font-size: 12px;
  color: #444444;
  line-height: 24px;
  margin-top: 5px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: normal;
    margin-top: ${mbSize(5)};
  }
`

export const MoreIcon = styled.img`
  width: 22px;
  height: 13px;
  margin-top: 18px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(26)};
    height: ${mbSize(16)};
    margin-top: ${mbSize(16)};
  }
`

export const MoreName = styled.div`
  color: #fff;
  margin-bottom: 12px;
  ${Media.lessThan(Media.small)} {
  }
`

export const MoreIntroduce = styled.div`
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
`

export const JudgeWrapper = styled.div`
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  gap: 60px 62px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(672)};
    gap: ${mbSize(40)} ${mbSize(36)};
    margin: 0 auto;
  }
`

export const JudgeMore = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  width: 203px;
  height: 265px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(7.6px);
  box-shadow: inset 0px 4px 21px 0px rgba(253, 204, 86, 0.2);
  opacity: 0;
  transition: all 0.3s;
  color: #fff;
  padding: 12px 0 12px 12px;
  display: flex;
  flex-direction: column;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const JudgeMoreTitle = styled.div`
  font-size: 16px;
  margin-bottom: 6px;
  ${Media.lessThan(Media.small)} {
  }
`

export const JudgeMoreIntroduce = styled.div`
  font-size: 10px;
  line-height: 16px;
  overflow: auto;
  flex: 1;
  padding-right: 12px;
  ${Media.lessThan(Media.small)} {
  }
`

export const JudgeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:hover {
    ${JudgeMore} {
      opacity: 1;
    }
  }
`

export const JudgeAvatar = styled.img`
  width: 148px;
  height: 148px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(200)};
    height: ${mbSize(200)};
  }
`

export const JudgeName = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #fff;
  margin: 12px 0 8px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    margin: ${mbSize(16)} 0 ${mbSize(10)};
  }
`

export const JudgeIdentity = styled.div`
  font-size: ${({ fontSize }) => fontSize || '10px'};
  line-height: 16px;
  color: #dcdcdc;
  text-align: center;
  width: 148px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const JudgeIdentityMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    color: #dcdcdc;
    text-align: center;
    font-size: ${mbSize(20)};
    width: ${mbSize(200)};
    line-height: normal;
  }
`

export const Decorate3 = styled.img`
  position: absolute;
  bottom: 180px;
  right: -260px;
  width: 310px;
  height: 67px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const MoreWrapperMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    animation: ${slideUp} 0.3s ease-out forwards;
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: fixed;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    width: ${mbSize(750)};
    max-height: ${mbSize(640)};
    border-radius: ${mbSize(24)} ${mbSize(24)} 0px 0px;
    padding: ${mbSize(32)} ${mbSize(32)} ${mbSize(80)};
    background: rgba(32, 32, 32, 0.8);
    backdrop-filter: blur(10px);
    box-shadow: inset 0px ${mbSize(8)} ${mbSize(20)} 0px rgba(245, 200, 75, 0.4);
  }
`

export const MoreHeaderMb = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${mbSize(32)};
  color: #ffffff;
  img {
    width: ${mbSize(36)};
    height: ${mbSize(36)};
  }
`

export const MoreContentMb = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${mbSize(28)};
  color: #ffffff;
  margin-top: ${mbSize(32)};
  font-size: ${mbSize(20)};
  overflow: auto;
`
