import styled, { css } from 'styled-components'
import buttonHover from '../../../images/rc2025/special-award-button-hover.webp'
import button from '../../../images/rc2025/special-award-button.webp'
import boxBg from '../../../images/rc2025/box-bg.webp'
import boxBgMb from '../../../images/rc2025/box-bg-mb.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-top: ${mbSize(60)};
    flex-direction: column;
    align-items: center;
  }
`

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 5px;
  ${Media.lessThan(Media.small)} {
    gap: ${mbSize(40)} ${mbSize(24)};
    margin-top: ${mbSize(60)};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Tab = styled.div`
  width: 222px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #e2e2e2;
  position: relative;
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  font-weight: 500;
  background-image: url(${({ active }) => (active ? buttonHover : button)});
  background-size: cover;
  background-position: center;
  transition: background-image 0.3s ease-in-out;
  span {
    ${({ active }) =>
      active &&
      css`
        background: linear-gradient(276deg, #f0feea 0%, #febd2f 24%, #26fdf8 71%, #ffffff 103%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(30)};
    width: ${mbSize(332)};
    height: ${mbSize(72)};
  }
`

export const Box = styled.div`
  width: 920px;
  height: 401px;
  background: url(${boxBg});
  background-size: 100%;
  padding: ${({ padding }) => padding};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(670)};
    height: ${mbSize(1054)};
    background: url(${boxBgMb});
    background-size: 100%;
    margin-top: ${mbSize(60)};
    padding: ${mbSize(100)} ${mbSize(36)} 0;
  }
`

export const BoxOl = styled.ol`
  padding: 0 0 0 16px;
  font-size: 16px;
  margin: 0 0 0;
  line-height: 36px;
  li {
    list-style: unset;
    color: #fff;
  }
  span {
    color: #febd2f;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(54)};
  }
`

export const QAWrapper = styled.div`
  width: 100%;
  height: 310px;
  overflow: auto;
  padding-right: 30px;
  ${Media.lessThan(Media.small)} {
    padding-right: unset;
    height: ${mbSize(850)};
  }
`

export const QADoc = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: rgba(254, 189, 47, 0.1);
  color: #ffffff;
  margin-bottom: 16px;
  width: fit-content;
  a {
    text-decoration: underline;
    color: #febd2f;
  }
  svg {
    margin-right: 8px;
  }
  &:hover {
    a {
      text-decoration: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    background: unset;
    padding: unset;
    font-size: ${mbSize(24)};
    svg {
      margin-right: ${mbSize(8)};
    }
  }
`

export const Question = styled.div`
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 4px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${mbSize(8)};
    font-size: ${mbSize(24)};
  }
`

export const Answer = styled.div`
  font-size: 14px;
  color: #bffffe;
  margin-bottom: 16px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin-bottom: ${mbSize(40)};
  }
`
