import React from 'react'
import { TitleImg, Wrapper } from './atoms'
import { Billboard } from '@renderbus/common/components'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query {
    banner: file(relativePath: { eq: "rc2025/banner.webp" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { eq: "rc2025/banner-mb.webp" }) {
      ...fluidImage
    }
    title: file(relativePath: { eq: "rc2025/title.webp" }) {
      ...fluidImage
    }
    titleMb: file(relativePath: { eq: "rc2025/title-mb.webp" }) {
      ...fluidImage
    }
  }
`

export const RC2025Banner = () => {
  const { title, titleMb, banner, bannerMb } = useStaticQuery(query)

  return (
    <Wrapper id='rc2025-banner'>
      <TitleImg>
        <Billboard
          fluid={[
            title.childImageSharp.fluid,
            { media: '(max-width: 600px)', ...titleMb.childImageSharp.fluid },
          ]}
          alt='标题'
        />
      </TitleImg>
      <Billboard
        fluid={[
          banner.childImageSharp.fluid,
          { media: '(max-width: 600px)', ...bannerMb.childImageSharp.fluid },
        ]}
        alt='banner'
      />
    </Wrapper>
  )
}
