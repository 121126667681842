import styled, { createGlobalStyle } from 'styled-components'
import YouSheBiaoTiHei from '../font/YouSheBiaoTiHei.ttf'
import { mbSize, Media } from '@renderbus/common/theme'

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 10548px;
  display: flex;
  flex-direction: column;
  align-items: center;
  *::-webkit-scrollbar {
    width: 4px;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    background: linear-gradient(357deg, #f0feea 7%, #febd2f 19%, #26fdf8 68%, #ffffff 100%);
    border-radius: 10px;
    background-clip: border-box;
  }
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(21220)};
  }
`

export const RC2025Style = createGlobalStyle`
  @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url(${YouSheBiaoTiHei});
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
`
