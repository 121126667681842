import React, { Fragment } from 'react'
import {
  Logo,
  LogoContainer,
  LogoWrapper,
  PartnerLine,
  PartnerRole,
  PartnerWrapper,
  Wrapper,
} from './atoms'
import { graphql, useStaticQuery } from 'gatsby'
import { Billboard } from '@renderbus/common/components'
import { CommonTitle } from '../atoms'
import titlePartner from '../../../images/rc2025/title-partner.webp'
import rayvision from '../../../images/rc2025/rayvision-logo.webp'
import renderbus from '../../../images/rc2025/renderbus-logo.webp'
import qingjiaoyun from '../../../images/rc2025/qingjiaoyun-logo.webp'
import pbrmax from '../../../images/rc2025/pbrmax-logo.webp'
// import rokoko from '../../../images/rc2025/rokoko-logo.webp'
import style3d from '../../../images/rc2025/style3d-logo.webp'
import deemos from '../../../images/rc2025/logo-deemos.webp'
import facegood from '../../../images/rc2025/facegood-logo.webp'
import groupLine from '../../../images/rc2025/group-line.webp'

const query = graphql`
  query {
    bg: file(relativePath: { eq: "rc2025/partner-bg.webp" }) {
      ...fluidImage
    }
  }
`

const partners = [
  {
    name: '主办方',
    image: [<Logo src={rayvision} />],
  },
  {
    name: '协办方',
    image: [<Logo src={renderbus} />, <Logo src={qingjiaoyun} />],
  },
  {
    name: '赞助方',
    image: [
      <Logo src={pbrmax} alt='pbrmax' />,
      <Logo src={style3d} alt='style3d' />,
      <Logo src={deemos} alt='deemos' width='106px' height='24px' widthMb={150} heightMb={30} />,
      <Logo src={facegood} alt='facegood' />,
    ],
  },
  {
    name: '支持方',
    supportNames: [
      <span>
        欧特克软件(中国)
        <br />
        有限公司
      </span>,
      <span>
        中国电影美术学会
        <br />
        CG艺术专委会
      </span>,
      <span>
        中央美术学院
        <br />
        国际数字艺术研究中心
      </span>,
      <span>
        中国传媒大学
        <br />
        动画与数字艺术学院
      </span>,
      <span>
        广州美术学院
        <br />
        视觉艺术设计学院
      </span>,
      <span>
        中国美术学院
        <br />
        创新设计学院
      </span>,
      <span>
        西安美术学院
        <br />
        影视动画系
      </span>,
      <span>
        上海工艺美术学会
        <br />
        数字艺术设计专业委员会
      </span>,
      <span>
        湖北美术学院
        <br />
        影视动画学院
      </span>,
      <span>
        北京电影学院
        <br />
        影视技术系
      </span>,
      <span>CG世界</span>,
      <span>CG模型网</span>,
      <span>哔哩哔哩寻光</span>,
      <span>wuhu动画人空间</span>,
      <span>Ahatime灵感社区</span>,
      <span>浩克肥虫</span>,
    ],
  },
]

export const RC2025Partner = () => {
  const { bg } = useStaticQuery(query)

  return (
    <Wrapper>
      <CommonTitle src={titlePartner} marginTop='160px' marginTopMb={120} />
      <PartnerWrapper>
        {partners.map((item, index) => (
          <Fragment key={index}>
            <PartnerRole>{item.name}</PartnerRole>
            <PartnerLine src={groupLine} alt='groupLine' />
            <LogoWrapper>
              {item.image
                ? item.image.map((item, i) => <LogoContainer key={i}>{item}</LogoContainer>)
                : item.supportNames.map((item, i) => <LogoContainer key={i}>{item}</LogoContainer>)}
            </LogoWrapper>
          </Fragment>
        ))}
      </PartnerWrapper>
      <Billboard className='bg' fluid={bg.childImageSharp.fluid} alt='bg' />
    </Wrapper>
  )
}
