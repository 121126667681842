import styled, { css } from 'styled-components'
import navBg from '../../../images/rc2025/nav-bg.webp'
import navHoverBg from '../../../images/rc2025/nav-hover-bg.webp'
import navRegisterBg from '../../../images/rc2025/nav-register-bg.webp'
import navRegisterHoverBg from '../../../images/rc2025/nav-register-hover-bg.webp'
import QRCodeBg from '../../../images/rc2025/qr-code-bg.webp'
import { Media } from '@renderbus/common/theme'

export const LeftWrapper = styled.div`
  position: fixed;
  z-index: 5;
  left: 0;
  top: 50vh;
  transform: translateY(-50%);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 4px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const RightWrapper = styled.div`
  position: fixed;
  z-index: 5;
  right: 0;
  top: 50vh;
  transform: translateY(-50%);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 4px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const NavItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 236px;
  height: 62px;
  transition: all 0.3s ease-in-out;
  background: url(${navBg});
  background-size: 100%;
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  color: #3d3d3d;
  font-size: 20px;
  padding-left: 20px;
  svg {
    margin-right: 8px;
  }
  &:hover {
    background: url(${navHoverBg});
    background-size: 100%;
    font-size: 28px;
    color: #ffffff;
  }
  ${({ active }) =>
    active &&
    css`
      background: url(${navHoverBg});
      background-size: 100%;
      font-size: 28px;
      color: #ffffff;
    `}
  @media (max-width: 1600px) {
    width: 175px;
    height: 46px;
    font-size: 18px;
    ${({ active }) =>
      active &&
      css`
        font-size: 24px;
      `}
  }
`

export const Register = styled.a`
  width: 236px;
  height: 62px;
  background: url(${navRegisterBg});
  background-size: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background: url(${navRegisterHoverBg});
    background-size: 100%;
  }
  @media (max-width: 1600px) {
    width: 175px;
    height: 46px;
  }
`

export const QRcode = styled.div`
  width: 236px;
  height: 143px;
  background: url(${QRCodeBg});
  background-size: 100%;
  @media (max-width: 1600px) {
    width: 175px;
    height: 107px;
  }
`
