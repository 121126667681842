import React, { useState } from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleSpecialAward from '../../../images/rc2025/title-special-award.webp'
import { graphql, useStaticQuery } from 'gatsby'
import {
  AwardImage,
  AwardWrapper,
  BonusItems,
  BonusTitle,
  BonusWrapper,
  Wrapper,
  Introduce,
  Tab,
  TabWrapper,
  TextWrapper,
  ContentWrapper,
  AwardMbWrapper,
  Arrow,
} from './atoms'
import { Billboard } from '@renderbus/common/components'
import specialModelMaster from '../../../images/rc2025/special-model-master.webp'
import specialAudienceChoice from '../../../images/rc2025/special-audience-choice.webp'
import specialVisualMagic from '../../../images/rc2025/special-visual-magic.webp'
import specialSolvingElite from '../../../images/rc2025/special-solving-elite.webp'
import specialRodin from '../../../images/rc2025/special-rodin.webp'
import specialMoreAward1 from '../../../images/rc2025/special-more-award1.webp'
import specialMoreAward2 from '../../../images/rc2025/special-more-award2.webp'
import specialMoreAward3 from '../../../images/rc2025/special-more-award3.webp'
import specialMoreAward4 from '../../../images/rc2025/special-more-award4.webp'
import specialMoreAward5 from '../../../images/rc2025/special-more-award5.webp'
import { LeftIcon } from '../icon/left-icon'
import { RightIcon } from '../icon/right-icon'

const query = graphql`
  query {
    specialAwardBg: file(relativePath: { eq: "rc2025/special-award-bg.webp" }) {
      ...fluidImage
    }
  }
`

const groups = ['Style3D特别奖4人', 'Rodin特别奖1人', '更多荣誉']

const prize = {
  [groups[0]]: {
    images: [specialModelMaster, specialSolvingElite, specialVisualMagic, specialAudienceChoice],
    introduce: ['Style3D特别奖（4人）', '投稿作品需使用Style3D Atelier或Style3D Simulator'],
    flexDirection: 'column',
    width: '990px',
    marginTop: '50px',
    gap: '30px',
    bonus: (
      <BonusWrapper>
        <BonusTitle>总奖金20,000元（单项5,000元）</BonusTitle>
        <BonusItems>
          <li className='yellow'>
            价值1,999元 <span>Style3D Simulator个人版一年使用权</span>
          </li>
          <li className='yellow'>
            价值1,888元 <span>Style3D Atelier个人版一年使用权</span>
          </li>
          <li>Style3D 4k+服装款式模型库 一年使用权</li>
        </BonusItems>
      </BonusWrapper>
    ),
  },
  [groups[1]]: {
    images: [specialRodin],
    introduce: ['Rodin 特别奖（1人）', '投稿作品创作过程中需使到Rodin'],
    flexDirection: 'row',
    marginTop: '110px',
    gap: '40px',
    bonus: (
      <BonusWrapper>
        <BonusTitle>奖品总价20,656元</BonusTitle>
        <BonusItems>
          <li className='yellow'>现金10,000元</li>
          <li className='yellow'>
            价值10,656元 <span>1年Rodin Business会员</span>
          </li>
        </BonusItems>
      </BonusWrapper>
    ),
  },
  [groups[2]]: {
    imgWidth: '212px',
    gap: '0',
    images: [
      specialMoreAward1,
      specialMoreAward2,
      specialMoreAward3,
      specialMoreAward4,
      specialMoreAward5,
    ],
    marginTop: '110px',
  },
}

export const RC2025SpecialAward = () => {
  const { specialAwardBg } = useStaticQuery(query)
  const [active, setActive] = useState(groups[0])
  const [itemActive, setItemActive] = useState(0)

  return (
    <CommonWrapper>
      <CommonTitle src={titleSpecialAward} />
      <Wrapper>
        <TabWrapper>
          {groups.map((item) => (
            <Tab
              key={item}
              onClick={() => {
                setActive(item)
                setItemActive(0)
              }}
              active={active === item}
            >
              <span>{item}</span>
            </Tab>
          ))}
        </TabWrapper>
        <ContentWrapper
          gap={prize[active]?.gap}
          width={prize[active]?.width}
          marginTop={prize[active]?.marginTop}
          flexDirection={prize[active]?.flexDirection}
        >
          <AwardWrapper>
            {prize[active]?.images?.map((item, index) => (
              <AwardImage key={index} src={item} alt='' width={prize[active]?.imgWidth} />
            ))}
          </AwardWrapper>
          <AwardMbWrapper>
            {prize[active].images.length > 1 && (
              <Arrow
                disabled={itemActive <= 0}
                onClick={() => itemActive > 0 && setItemActive(itemActive - 1)}
              >
                <LeftIcon />
              </Arrow>
            )}
            <AwardImage src={prize[active].images[itemActive]} alt='' />
            {prize[active].images.length > 1 && (
              <Arrow
                disabled={itemActive >= prize[active].images.length - 1}
                onClick={() =>
                  itemActive < prize[active].images.length - 1 && setItemActive(itemActive + 1)
                }
              >
                <RightIcon />
              </Arrow>
            )}
          </AwardMbWrapper>
          <TextWrapper width={prize[active]?.width}>
            <Introduce>
              {prize[active]?.introduce?.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </Introduce>
            {prize[active]?.bonus}
          </TextWrapper>
        </ContentWrapper>
        <Billboard className='bg' fluid={specialAwardBg.childImageSharp.fluid} alt='' />
      </Wrapper>
    </CommonWrapper>
  )
}
