import React, { useState } from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleAbout from '../../../images/rc2025/title-about.webp'
import exchangeCode from '../../../images/rc2025/exchange-code.webp'
import {
  Box,
  BoxOl,
  Content,
  ExchangeTextWrapper,
  ExchangeWrapper,
  Introduction,
  QRCode,
  SignUpButton,
  Tab,
  TabWrapper,
} from './atoms'

const tabs = ['获奖通知', '相关权益', '赛事交流', '大赛简介']

const awardNotice = [
  '现金部分奖金均为税前金额，将由主办方代扣代缴个人所得税，获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式；',
  '获奖作者在活动结束后请注意查收邮件，并根据邮件提示内容及时配合主办方完成作品收集及奖品发放等工作；',
  '若获奖作者在限定时间内对获奖通知未做回应，则视为放弃奖项，由其他参赛作者取代；',
  '主办方将在比赛结束后安排奖品发放事宜，同一获奖作者可获得多个奖项（人气奖、特别奖）；',
  '最终赛果将在主办网站、协办网站等平台进行公示，并通过各媒体渠道进行曝光宣传；',
]

const rightInterest = [
  '所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权；',
  '主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，参赛者保留对其作品的著作权。主办方及合作方有权将用于市场活动的作品加入赛事水印；',
  '作品一经提交，将视为参赛者同意并遵守比赛相关规定，若作品在商业使用中其著作权存在争议，主办方不承担因作品侵犯他人（或单位）的权利而产生的法律责任，由提供作品的参赛者承担全部法律责任；',
  '对存在争议的作品，大设计奖组委会有权保留甚至撤销该作品的参赛资格。如获奖作品存在知识产权等争议，大设计奖组委会有权撤销获奖资格，召回奖状、奖杯、奖品等荣誉；',
  '本次大赛最终解释权归主办方瑞云科技所有；',
]

const exchange = [
  '想跟技术大咖们学习CG技巧吗？',
  '想和CG小伙伴们交流创作经验吗？',
  <>
    扫码或微信添加小助手<span>rayvision1</span>，并备注<span>“渲染大赛”</span>
  </>,
  '加入我们的赛事交流群，一起玩转创意！',
  <>
    官方通知邮箱<span>RRC@rayvision.com</span>
  </>,
]

const introduce = [
  '由国内知名视觉云计算服务平台“瑞云科技”主办发起的瑞云3D渲染动画创作大赛（简称瑞云渲染大赛）旨在发掘和培养优秀3D动画人才、为CG创作者们提供展示自我的机会！截至目前已圆满举办了三届，成为了国内3D动画艺术垂直领域备受瞩目的专业赛事，吸引了数以千计的CG艺术家加入，破圈作品频出。',
  '大赛首届举办于2021年12月，以“翻转世界”为题，第二届大赛以“圆形轮廓”为题，第三届大赛以“大Y元素”为题，参赛者需要以瑞云提供的3D动画模板场景为基础，进行7秒的3D动画渲染创作。',
  '3D动画崛起，必有我辈！瑞云3D渲染动画创作大赛旨在发掘和培养优秀3D动画人才、为CG创作者们提供展示自我的机会！大赛云集3D动画爱好者们一起探索与交流前沿的CG技术，创造属于自己的精彩！',
]

const contents = {
  [tabs[0]]: (
    <Box padding='50px 60px 0'>
      <BoxOl>
        {awardNotice.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </BoxOl>
    </Box>
  ),
  [tabs[1]]: (
    <Box padding='50px 60px 0'>
      <BoxOl>
        {rightInterest.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </BoxOl>
    </Box>
  ),
  [tabs[2]]: (
    <Box padding='120px 136px'>
      <ExchangeWrapper>
        <QRCode src={exchangeCode} alt='qr-code' />
        <ExchangeTextWrapper>
          {exchange.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </ExchangeTextWrapper>
      </ExchangeWrapper>
    </Box>
  ),
  [tabs[3]]: (
    <Box padding='50px 60px 0'>
      <Introduction>
        {introduce.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Introduction>
    </Box>
  ),
}

export const RC2025About = () => {
  const [active, setActive] = useState(tabs[0])

  return (
    <CommonWrapper id='rc2025-about'>
      <CommonTitle src={titleAbout} alt='关于大赛' marginTopMb={120} />
      <Content>
        {contents[active]}
        <TabWrapper>
          {tabs.map((item) => (
            <Tab key={item} active={active === item} onClick={() => setActive(item)}>
              <span>{item}</span>
            </Tab>
          ))}
        </TabWrapper>
      </Content>
      <SignUpButton href='/rc-form.html' target='__blank'>
        <span>立即报名</span>
      </SignUpButton>
    </CommonWrapper>
  )
}
