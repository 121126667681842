import React, { Fragment, useState } from 'react'
import { CommonTitle } from '../atoms'
import titlePrize from '../../../images/rc2025/title-prize.webp'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Decorate1,
  Decorate2,
  PrizeBonus,
  PrizeDivider,
  PrizeItem,
  PrizeText,
  PrizeTitle,
  PrizeWorth,
  PrizeWrapper,
  Tab,
  TabWrapper,
  ContentWrapper,
  Wrapper,
  PrizeWrapperMb,
  Arrow,
} from './atoms'
import { Billboard } from '@renderbus/common/components'
import goldLongBg from '../../../images/rc2025/gold-long-bg.webp'
import silverLongBg from '../../../images/rc2025/silver-long-bg.webp'
import bronzeLongBg from '../../../images/rc2025/bronze-long-bg.webp'
import excellentBg from '../../../images/rc2025/excellent-bg.webp'
import decorate1 from '../../../images/rc2025/decorate1.webp'
import decorate2 from '../../../images/rc2025/decorate2.webp'
import { LeftIcon } from '../icon/left-icon'
import { RightIcon } from '../icon/right-icon'

const query = graphql`
  query {
    prizeBg: file(relativePath: { eq: "rc2025/prize-bg.webp" }) {
      ...fluidImage
    }
    prizeBgMb: file(relativePath: { eq: "rc2025/prize-bg-mb.webp" }) {
      ...fluidImage
    }
  }
`

const groups = ['专业组', '学生组', '优秀奖', '人气奖']

const prize = {
  [groups[0]]: [
    {
      title: '冠军 x1',
      titleColor: '#FACE61',
      bonus: '￥50,000现金',
      bg: goldLongBg,
      worthColor: '#FFD69B',
      textColor: 'rgba(206, 192, 171, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值3,000元', text: 'Renderbus瑞云渲染无门槛渲染券3000元（6个月有效期）' },
        { worth: '价值1,999元', text: '青椒云电脑一体机+6个月云桌面1000元代金券' },
        {
          worth: '价值3,999元',
          text: 'PBRMAX⼤师版1年（价值1999元）+ 1500次下载加油包（价值2000元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
    {
      title: '亚军 x2',
      titleColor: '#E2E2E2',
      bonus: '￥30,000现金',
      bg: silverLongBg,
      worthColor: '#E2E2E2',
      textColor: 'rgba(199, 199, 199, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值1,500元', text: 'Renderbus瑞云渲染无门槛渲染券1500元（6个月有效期）' },
        { worth: '价值1,999元', text: '青椒云电脑一体机+6个月云桌面1000元代金券' },
        {
          worth: '价值3,499元',
          text: 'PBRMAX⼤师版1年（价值1999元）+ 500次下载加油包（价值1500元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
    {
      title: '季军 x3',
      titleColor: '#D3976E',
      bonus: '￥20,000现金',
      bg: bronzeLongBg,
      worthColor: '#D3976E',
      textColor: 'rgba(229, 210, 182, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值1,000元', text: 'Renderbus瑞云渲染无门槛渲染券1000元（6个月有效期）' },
        { worth: '价值1,000元', text: '青椒云桌面1000元代金券（6个月有效期）' },
        {
          worth: '价值2,999元',
          text: 'PBRMAX⼤师版1年（价值1999元）+ 250次下载加油包（价值1000元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
  ],
  [groups[1]]: [
    {
      title: '冠军 x1',
      titleColor: '#FACE61',
      bonus: '￥30,000现金',
      bg: goldLongBg,
      worthColor: '#FFD69B',
      textColor: 'rgba(206, 192, 171, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值3,000元', text: 'Renderbus瑞云渲染无门槛渲染券3000元（6个月有效期）' },
        { worth: '价值1,999元', text: '青椒云电脑一体机+6个月云桌面1000元代金券' },
        {
          worth: '价值2,599元',
          text: 'PBRMAX专业版1年（价值599元）+ 1500次下载加油包（价值2000元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
    {
      title: '亚军 x2',
      titleColor: '#E2E2E2',
      bonus: '￥20,000现金',
      bg: silverLongBg,
      worthColor: '#E2E2E2',
      textColor: 'rgba(199, 199, 199, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值1,500元', text: 'Renderbus瑞云渲染无门槛渲染券1500元（6个月有效期）' },
        { worth: '价值1,999元', text: '青椒云电脑一体机+6个月云桌面1000元代金券' },
        {
          worth: '价值2,099元',
          text: 'PBRMAX专业版1年（价值599元）+ 500次下载加油包（价值1500元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
    {
      title: '季军 x3',
      titleColor: '#D3976E',
      bonus: '￥10,000现金',
      bg: bronzeLongBg,
      worthColor: '#D3976E',
      textColor: 'rgba(229, 210, 182, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画' },
        { worth: '价值1,000元', text: 'Renderbus瑞云渲染无门槛渲染券1000元（6个月有效期）' },
        { worth: '价值1,000元', text: '青椒云桌面1000元代金券（6个月有效期）' },
        {
          worth: '价值1,599元',
          text: 'PBRMAX专业版1年（价值599元）+ 250次下载加油包（价值1000元）',
        },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
  ],
  [groups[2]]: [
    {
      isRainbow: true,
      title: '优秀奖 x10',
      titleColor:
        'linear-gradient(274deg, #F0FEEA -1%, #FEBD2F 24%, #26FDF8 71%, #FFFFFF 105%), #E2E2E2',
      bonus: '￥5,000现金',
      bg: excellentBg,
      worthColor:
        'linear-gradient(274deg, #F0FEEA -1%, #FEBD2F 24%, #26FDF8 71%, #FFFFFF 105%), #E2E2E2',
      textColor: 'rgba(199, 199, 199, 0.6)',
      content: [
        { worth: '价值1,000元', text: 'Renderbus瑞云渲染无门槛渲染券1000元（6个月有效期）' },
        { worth: '价值500元', text: '青椒云桌面500元代金券（6个月有效期）' },
        { worth: '价值599元', text: 'PBRMAX专业版1年（价值599元）' },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
  ],
  [groups[3]]: [
    {
      isRainbow: true,
      title: '人气奖 x6',
      titleColor:
        'linear-gradient(274deg, #F0FEEA -1%, #FEBD2F 24%, #26FDF8 71%, #FFFFFF 105%), #E2E2E2',
      bonus: '￥5,000现金',
      bg: excellentBg,
      worthColor:
        'linear-gradient(274deg, #F0FEEA -1%, #FEBD2F 24%, #26FDF8 71%, #FFFFFF 105%), #E2E2E2',
      textColor: 'rgba(199, 199, 199, 0.6)',
      content: [
        { worth: '价值10,000元', text: 'ALLHOLO创作者定制版裸眼3D光场全息画（价值10000元）' },
        { worth: '价值1,000元', text: 'Renderbus瑞云渲染无门槛渲染券1000元（6个月有效期）' },
        { worth: '价值500元', text: '青椒云桌面500元代金券（6个月有效期）' },
        { worth: '价值1,999元', text: 'PBRMAX⼤师版1年（价值1999元）' },
        {
          fontSize: '10px',
          worth: '价值1,888元',
          text: 'Style3D Simulator 个人版1年使用权（价值1,999元）+Style3D Atelier 个人版1年使用权（价值1,888元）+Style3D 4k+服装款式模型库（1年使用权）',
        },
        { worth: '价值10,656元', text: '1年Rodin Business会员' },
      ],
    },
  ],
}

export const RC2025Prize = () => {
  const { prizeBg, prizeBgMb } = useStaticQuery(query)
  const [active, setActive] = useState(groups[0])
  const [itemActive, setItemActive] = useState(0)

  return (
    <Wrapper id='rc2025-prize'>
      <CommonTitle src={titlePrize} alt='大赛奖品' />
      <ContentWrapper>
        <TabWrapper>
          {groups.map((item) => (
            <Tab
              key={item}
              onClick={() => {
                setActive(item)
                setItemActive(0)
              }}
              active={active === item}
            >
              <span>{item}</span>
            </Tab>
          ))}
        </TabWrapper>
        <PrizeWrapper>
          {prize[active]?.map((item) => (
            <PrizeItem key={item.title} bg={item.bg}>
              <PrizeTitle color={item.titleColor} isRainbow={item.isRainbow}>
                {item.title}
              </PrizeTitle>
              <PrizeDivider color={item.titleColor} />
              <PrizeBonus color={item.worthColor} isRainbow={item.isRainbow}>
                {item.bonus}
              </PrizeBonus>
              {item.content.map((contentItem, index) => (
                <Fragment key={index}>
                  <PrizeWorth color={item.worthColor} isRainbow={item.isRainbow}>
                    {contentItem.worth}
                  </PrizeWorth>
                  <PrizeText color={item.textColor} fontSize={contentItem.fontSize}>
                    {contentItem.text}
                  </PrizeText>
                </Fragment>
              ))}
            </PrizeItem>
          ))}
        </PrizeWrapper>
        <PrizeWrapperMb>
          {prize[active].length > 1 && (
            <Arrow
              disabled={itemActive <= 0}
              onClick={() => itemActive > 0 && setItemActive(itemActive - 1)}
            >
              <LeftIcon />
            </Arrow>
          )}
          <PrizeItem bg={prize[active][itemActive].bg}>
            <PrizeTitle
              color={prize[active][itemActive].titleColor}
              isRainbow={prize[active][itemActive].isRainbow}
            >
              {prize[active][itemActive].title}
            </PrizeTitle>
            <PrizeDivider color={prize[active][itemActive].titleColor} />
            <PrizeBonus
              color={prize[active][itemActive].worthColor}
              isRainbow={prize[active][itemActive].isRainbow}
            >
              {prize[active][itemActive].bonus}
            </PrizeBonus>
            {prize[active][itemActive].content.map((contentItem, index) => (
              <Fragment key={index}>
                <PrizeWorth
                  color={prize[active][itemActive].worthColor}
                  isRainbow={prize[active][itemActive].isRainbow}
                >
                  {contentItem.worth}
                </PrizeWorth>
                <PrizeText
                  color={prize[active][itemActive].textColor}
                  fontSize={contentItem.fontSize}
                >
                  {contentItem.text}
                </PrizeText>
              </Fragment>
            ))}
          </PrizeItem>
          {prize[active].length > 1 && (
            <Arrow
              disabled={itemActive >= prize[active].length - 1}
              onClick={() => itemActive < prize[active].length - 1 && setItemActive(itemActive + 1)}
            >
              <RightIcon />
            </Arrow>
          )}
        </PrizeWrapperMb>
        <Billboard
          fluid={[
            prizeBg.childImageSharp.fluid,
            { media: '(max-width: 600px)', ...prizeBgMb.childImageSharp.fluid },
          ]}
          alt=''
        />
        <Decorate1 src={decorate1} alt='decorate1' loading='lazy' />
        <Decorate2 src={decorate2} alt='decorate2' loading='lazy' />
      </ContentWrapper>
    </Wrapper>
  )
}
