import React from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleTime from '../../../images/rc2025/title-time.webp'
import { Item, Line, Time, TimeWrapper } from './atoms'
import timeItemBg from '../../../images/rc2025/time-item-bg.webp'

const items = [
  { title: '作品创作及提交', time: '2024/12/11——2025/03/09' },
  { title: '评委评选时间', time: '2024/03/09——2025/03/25' },
  { title: '赛果公布', time: '2025/03/28' },
]

export const RC2025Time = () => {
  return (
    <CommonWrapper id='rc2025-time'>
      <CommonTitle src={titleTime} alt='大赛时间' />
      <TimeWrapper>
        {items.map((item) => (
          <Item key={item.title} time={item.time}>
            {item.title}
            <img src={timeItemBg} alt='' loading='lazy' />
            <Time>{item.time}</Time>
          </Item>
        ))}
        <Line />
      </TimeWrapper>
    </CommonWrapper>
  )
}
