import styled, { css } from 'styled-components'
import buttonHover from '../../../images/rc2025/prize-button-hover.webp'
import button from '../../../images/rc2025/prize-button.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const Wrapper = styled.div`
  min-width: 1200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${Media.lessThan(Media.small)} {
    min-width: unset;
    height: fit-content;
  }
`

export const ContentWrapper = styled.div`
  width: 1200px;
  height: 984px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: ${mbSize(1700)};
    margin-top: ${mbSize(60)};
  }
`

export const TabWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: ${mbSize(40)} ${mbSize(32)};
  }
`

export const Tab = styled.div`
  width: 188px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #e2e2e2;
  position: relative;
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  font-weight: 500;
  background-image: url(${({ active }) => (active ? buttonHover : button)});
  background-size: cover;
  background-position: center;
  transition: background-image 0.3s ease-in-out;
  span {
    ${({ active }) =>
      active &&
      css`
        background: linear-gradient(276deg, #f0feea 0%, #febd2f 24%, #26fdf8 71%, #ffffff 103%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
    width: ${mbSize(254)};
    height: ${mbSize(66)};
  }
`

export const PrizeWrapper = styled.div`
  display: flex;
  gap: 80px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const PrizeWrapperMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
    align-items: center;
    gap: ${mbSize(40)};
    margin-top: ${mbSize(64)};
    svg {
      transition: all 0.3s;
    }
  }
`

export const PrizeItem = styled.div`
  width: 232px;
  height: 692px;
  background-image: url(${({ bg }) => bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(464)};
    height: ${mbSize(1300)};
  }
`

export const PrizeTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-top: 32px;
  ${({ isRainbow, color }) =>
    isRainbow
      ? css`
          background: linear-gradient(273deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 105%),
            #e2e2e2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        `
      : css`
          color: ${color};
        `};
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(52)};
    margin-top: ${mbSize(64)};
  }
`

export const PrizeDivider = styled.div`
  width: 182px;
  height: 2px;
  background: ${({ color }) => color};
  margin: 30px 0;
  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(60)} 0;
    width: ${mbSize(364)};
    height: ${mbSize(4)};
  }
`

export const PrizeBonus = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 14px;
  ${({ isRainbow, color }) =>
    isRainbow
      ? css`
          background: linear-gradient(273deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 105%),
            #e2e2e2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        `
      : css`
          color: ${color};
        `};
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(32)};
    margin-bottom: ${mbSize(32)};
  }
`

export const PrizeWorth = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  ${({ isRainbow, color }) =>
    isRainbow
      ? css`
          background: linear-gradient(273deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 105%),
            #e2e2e2;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        `
      : css`
          color: ${color};
        `};
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    margin-bottom: ${mbSize(8)};
  }
`

export const PrizeText = styled.div`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  width: 196px;
  text-align: center;
  color: ${({ color }) => color};
  margin-bottom: 14px;
  ${Media.lessThan(Media.small)} {
    font-size: ${({ fontSize }) => fontSize || mbSize(24)};
    margin-bottom: ${mbSize(32)};
  }
`

export const Decorate1 = styled.img`
  position: absolute;
  top: 732px;
  left: -62px;
  height: 391px;
  width: 110px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Decorate2 = styled.img`
  position: absolute;
  top: 94px;
  left: 1110px;
  width: 311px;
  height: 82px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Arrow = styled.div`
  margin-bottom: ${mbSize(50)};
  svg {
    fill: ${({ disabled }) => (disabled ? '#494949' : '#FEBD2F')};
  }
`
