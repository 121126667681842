import React, { useState } from 'react'
import { LeftWrapper, NavItem, QRcode, Register, RightWrapper } from './atoms'
import { useScroll } from '@renderbus/common/hooks'

export const navItems = [
  {
    name: '1.大赛引言',
    anchor: '#rc2025-introduction',
  },
  {
    name: '2.大赛时间',
    anchor: '#rc2025-time',
  },
  {
    name: '3.面向群体',
    anchor: '#rc2025-group',
  },
  {
    name: '4.大赛奖品',
    anchor: '#rc2025-prize',
  },
  {
    name: '5.评委阵容',
    anchor: '#rc2025-judge',
  },
  {
    name: '6.大赛内容',
    anchor: '#rc2025-content',
  },
  {
    name: '7.设计说明',
    anchor: '#rc2025-illustrate',
  },
  {
    name: '8.关于大赛',
    anchor: '#rc2025-about',
  },
]

export const RC2025Navigation = ({ contentRef }) => {
  const [active, setActive] = useState('')
  const [showPCNav, setShowPCNav] = useState(false)

  const handleScroll = () => {
    const anchorNode = [...contentRef.current.children].filter((node) =>
      node.id.includes('rc2025-'),
    )
    for (let node of anchorNode) {
      const offset = node.getBoundingClientRect()
      if (window.scrollY > 600) {
        setShowPCNav(true)
      } else {
        setShowPCNav(false)
        setActive('#rc-top')
      }
      if (offset.top <= window.innerHeight && offset.bottom >= 0) {
        setActive(`#${node.id}`)
        break
      }
    }
  }

  useScroll(handleScroll)

  const handleScrollTo = (id) => {
    const target = document.getElementById(id.substring(1))
    const { top } = target.getBoundingClientRect()
    if (target) {
      window.scrollTo({ top: top + window.scrollY + 1, behavior: 'smooth' })
    }
    setActive(id)
  }

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <LeftWrapper show={showPCNav}>
        {navItems.map((item) => (
          <NavItem
            key={item.anchor}
            active={active === item.anchor}
            onClick={() => handleScrollTo(item.anchor)}
          >
            {item.name}
          </NavItem>
        ))}
        <NavItem onClick={handleScrollTop}>
          <svg width='14' height='10' viewBox='0 0 14 10'>
            <defs>
              <clipPath id='master_svg0_71_7507'>
                <rect x='0' y='0' width='14.002115249633789' height='9.998188972473145' rx='0' />
              </clipPath>
            </defs>
            <g clipPath='url(#master_svg0_71_7507)'>
              <g>
                <path
                  d='M12.999560501098633,6.000730250549316L0.9973983760986328,6.000730250549316L6.9984605010986325,-0.0003242494506835937L12.999560501098633,6.000730250549316Z'
                  fillRule='evenodd'
                  fill='currentColor'
                  fillOpacity='1'
                />
              </g>
              <g>
                <rect
                  x='1'
                  y='7'
                  width='12'
                  height='2'
                  rx='0'
                  fill='currentColor'
                  fillOpacity='1'
                />
              </g>
            </g>
          </svg>
          返回顶部
        </NavItem>
      </LeftWrapper>
      <RightWrapper show={showPCNav}>
        <Register href='/rc-form.html' target='__blank' />
        <QRcode />
      </RightWrapper>
    </>
  )
}
