import styled, { css } from 'styled-components'
import register from '../../../images/rc2025/register.webp'
import registerHover from '../../../images/rc2025/register-hover.webp'
import registerMb from '../../../images/rc2025/register-mb.webp'
import { fadeIn, mbSize, Media } from '@renderbus/common/theme'
import headerNavBg from '../../../images/rc2025/header-nav-bg.webp'
import headerNavHoverBg from '../../../images/rc2025/header-nav-hover-bg.webp'

export const Wrapper = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 60px;
  background: #1b1017;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 3;
  ${Media.lessThan(Media.small)} {
    position: fixed;
    height: ${mbSize(90)};
    min-width: 100%;
    top: 0px;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${mbSize(28)} 0 ${mbSize(24)};
  }
`

export const Nav = styled.div`
  position: relative;
  color: #fff;
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 4px;
    transition: all 0.3s;
    transform: rotate(${({ open }) => (open ? '0deg' : '180deg')});
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const NavText = styled.div`
  position: relative;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    height: 6px;
    background: #cf4fc9;
    opacity: 0;
    transform-origin: center;
    transform: translate(-50%, 0) scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    &::after {
      transform: translate(-50%, 0) scaleX(1);
      opacity: 1;
    }
  }
`

export const NavDropDownWrapper = styled.div`
  padding: 20px 6px;
  background: #1b1017;
  position: absolute;
  top: 100%;
  z-index: 3;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: #cf4fc9;
  }
`

export const NavDropDownItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 35px;
  font-size: 14px;
  color: #6d6d6d;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #353535;
    color: #a2a2a2;
  }
  svg {
    margin-bottom: 5px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
    svg {
      width: ${mbSize(12)};
      height: ${mbSize(12)};
      margin: 0 0 ${mbSize(10)} ${mbSize(8)};
    }
  }
`

export const TitleMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    color: #ffffff;
    display: block;
    .title {
      font-size: ${mbSize(24)};
      margin-bottom: ${mbSize(4)};
    }
    .time {
      font-size: ${mbSize(20)};
    }
  }
`

export const RegisterImg = styled.a`
  width: 200px;
  height: 60px;
  position: relative;
  background: url(${register});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: url(${registerHover});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(170)};
    height: ${mbSize(71)};
    background: url(${registerMb});
    background-size: 100%;
    &:hover {
      background: url(${registerMb});
      background-size: 100%;
    }
  }
`

export const PreviousIcon = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${mbSize(70)};
    height: ${mbSize(70)};
    img {
      width: ${({ open }) => mbSize(open ? 42 : 32)};
      height: ${({ open }) => mbSize(open ? 42 : 32)};
    }
  }
`

export const PreviousItemWrapper = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    background: #1b1017;
    width: ${mbSize(326)};
    padding: ${mbSize(40)} ${mbSize(12)};
    position: absolute;
    top: ${mbSize(90)};
    right: 0;
    border-radius: 4px;
  }
`

export const MenuIcon = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${mbSize(70)};
    height: ${mbSize(70)};
    img {
      width: ${({ open }) => mbSize(open ? 42 : 30)};
      height: ${({ open }) => mbSize(open ? 42 : 24)};
    }
  }
`

export const MenuItemWrapper = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    position: absolute;
    top: ${mbSize(130)};
    right: 0;
    display: flex;
    flex-direction: column;
    gap: ${mbSize(6)};
    animation: ${fadeIn} 300ms;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: ${mbSize(354)};
  height: ${mbSize(93)};
  transition: all 0.3s ease-in-out;
  background: url(${headerNavBg});
  background-size: 100%;
  backdrop-filter: blur(3px);
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  color: #3d3d3d;
  font-size: ${mbSize(30)};
  padding-right: ${mbSize(30)};
  svg {
    margin-right: ${mbSize(12)};
    width: ${mbSize(21)};
    height: ${mbSize(15)};
  }
  ${({ active }) =>
    active &&
    css`
      background: url(${headerNavHoverBg});
      background-size: 100%;
      font-size: ${mbSize(42)};
      color: #ffffff;
    `}
`
