import React from 'react'
import { Wrapper } from './atoms'
import recordIcon from '../../../images/rc2025/record-icon.webp'

export const RC2025RecordKeep = () => {
  return (
    <Wrapper>
      <div>
        <img src={recordIcon} alt='' />
        <span>
          © 2024
          <a href='https://www.rayvision.com/' rel='nofollow'>
            深圳市瑞云科技股份有限公司
          </a>
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target='__blank' rel='nofollow'>
            粤ICP备12028569号
          </a>
        </span>
      </div>
      <div>© 2024深圳市瑞云科技股份有限公司粤ICP备12028569号</div>
    </Wrapper>
  )
}
