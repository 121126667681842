import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || 0};
  ${Media.lessThan(Media.small)} {
    padding-bottom: unset;
  }
`

export const CommonTitle = styled.img`
  width: 405px;
  height: 119px;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || '110px'};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(558)};
    height: ${mbSize(188)};
    margin-top: ${({ marginTopMb }) => mbSize(marginTopMb || 144)};
  }
`
