import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vw * 108 / 192);
  min-height: 810px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(960)};
    min-height: unset;
    margin-top: ${mbSize(90)};
  }
`

export const TitleImg = styled.div`
  position: relative;
  width: 819px;
  height: 367px;
  z-index: 1;
  margin: 0 0 180px 472px;
  ${Media.lessThan(Media.small)} {
    position: absolute;
    width: ${mbSize(688)};
    height: ${mbSize(336)};
    margin: 0;
    right: ${mbSize(-48)};
    bottom: ${mbSize(354)};
  }
`
