import React from 'react'
import { TextWrapper, Title } from './atoms'
import titleIntroduction from '../../../images/rc2025/title-introduction.webp'
import { CommonWrapper } from '../atoms'

export const RC2025Introduction = () => {
  return (
    <CommonWrapper id='rc2025-introduction'>
      <Title src={titleIntroduction} alt='大赛引言' />
      <TextWrapper>
        <span>“新”怀期待，再踏征”途“</span>
        <span>每一次启程，都是对世界边界的勇敢丈量</span>
        <span>每一次抵达，都是对自我能力的极限挑战</span>
        <span>瑞云官方流量扶持，大V媒体精准曝光</span>
        <span>高视野的行业交流，更多的业界合作机会</span>
        <span>大探险家们，你们准备好了吗？</span>
      </TextWrapper>
    </CommonWrapper>
  )
}
