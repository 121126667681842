import React from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleGroup from '../../../images/rc2025/title-group.webp'
import groupLine from '../../../images/rc2025/group-line.webp'
import groupPractitioner from '../../../images/rc2025/group-practitioner.webp'
import groupStudent from '../../../images/rc2025/group-student.webp'
import { GroupItem, GroupWrapper } from './atoms'

const groups = [
  { title: '专业组', img: groupPractitioner },
  { title: '学生组', img: groupStudent },
]

export const RC2025Group = () => {
  return (
    <CommonWrapper id='rc2025-group'>
      <CommonTitle src={titleGroup} alt='大赛组别' marginTop='120px' />
      <GroupWrapper>
        {groups.map((item) => (
          <GroupItem key={item.title}>
            <span>{item.title}</span>
            <img src={groupLine} alt='line' className='line' loading='lazy' />
            <img src={item.img} alt={item.title} className='pic' loading='lazy' />
          </GroupItem>
        ))}
      </GroupWrapper>
    </CommonWrapper>
  )
}
