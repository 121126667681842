import styled, { css } from 'styled-components'
import buttonHover from '../../../images/rc2025/special-award-button-hover.webp'
import button from '../../../images/rc2025/special-award-button.webp'
import specialAwardMbBg from '../../../images/rc2025/special-award-mb-bg.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const Wrapper = styled.div`
  width: 1200px;
  height: 742px;
  position: relative;
  z-index: 1;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: ${mbSize(1700)};
    margin-top: ${mbSize(80)};
    background: rgba(112, 112, 112, 0.16);
    .bg {
      display: none;
    }
  }
`

export const TabWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    justify-content: center;
    gap: ${mbSize(40)} ${mbSize(32)};
    margin-top: ${mbSize(80)};
  }
`

export const Tab = styled.div`
  width: 222px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #e2e2e2;
  position: relative;
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  font-weight: 500;
  background-image: url(${({ active }) => (active ? buttonHover : button)});
  background-size: 100%;
  background-position: center;
  transition: background-image 0.3s ease-in-out;
  span {
    ${({ active }) =>
      active &&
      css`
        background: linear-gradient(276deg, #f0feea 0%, #febd2f 24%, #26fdf8 71%, #ffffff 103%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
    width: ${mbSize(304)};
    height: ${mbSize(66)};
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  margin-top: ${({ marginTop }) => marginTop};
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap || '40px'};
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    gap: ${mbSize(40)};
    margin-top: ${mbSize(76)};
    padding-top: ${mbSize(110)};
    background: url(${specialAwardMbBg});
    background-size: 100%;
    width: ${mbSize(664)};
    height: ${mbSize(1291)};
  }
`

export const AwardWrapper = styled.div`
  display: flex;
  gap: 18px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const AwardMbWrapper = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
    gap: ${mbSize(29)};
    align-items: center;
    svg {
      transition: all 0.3s;
    }
  }
`

export const AwardImage = styled.img`
  width: ${({ width }) => width || '234px'};
  height: 284px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(480)};
    height: ${mbSize(582)};
  }
`

export const TextWrapper = styled.div`
  width: ${({ width }) => width};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(570)};
  }
`

export const Introduce = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(45)};
  }
`

export const BonusWrapper = styled.div`
  margin-top: 26px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(40)};
  }
`

export const BonusTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #febd2f;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
  }
`

export const BonusItems = styled.ul`
  padding: 0 0 0 16px;
  font-size: 16px;
  margin: 8px 0 0 0;
  span {
    color: #fff;
  }
  li {
    list-style: unset;
    color: #fff;
    margin-bottom: 8px;
  }
  li.yellow {
    color: #febd2f;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: normal;
  }
`

export const Arrow = styled.div`
  margin-bottom: ${mbSize(50)};
  svg {
    fill: ${({ disabled }) => (disabled ? '#494949' : '#FEBD2F')};
  }
`
