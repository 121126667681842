import styled, { css } from 'styled-components'
import buttonHover from '../../../images/rc2025/special-award-button-hover.webp'
import button from '../../../images/rc2025/special-award-button.webp'
import boxBg from '../../../images/rc2025/box-bg.webp'
import boxBgMb from '../../../images/rc2025/box-bg-mb.webp'
import signUpBg from '../../../images/rc2025/sign-up-bg.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column-reverse;
    width: ${mbSize(690)};
    margin-top: ${mbSize(60)};
    align-items: center;
  }
`

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 5px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    margin-top: unset;
    flex-direction: row;
    gap: ${mbSize(40)} ${mbSize(24)};
  }
`

export const Tab = styled.div`
  width: 222px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #e2e2e2;
  position: relative;
  cursor: pointer;
  font-family: YouSheBiaoTiHei;
  font-weight: 500;
  background-image: url(${({ active }) => (active ? buttonHover : button)});
  background-size: cover;
  background-position: center;
  transition: background-image 0.3s ease-in-out;
  span {
    ${({ active }) =>
      active &&
      css`
        background: linear-gradient(276deg, #f0feea 0%, #febd2f 24%, #26fdf8 71%, #ffffff 103%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      `}
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(332)};
    height: ${mbSize(72)};
    font-size: ${mbSize(31)};
  }
`

export const Box = styled.div`
  width: 919px;
  height: 401px;
  background: url(${boxBg});
  background-size: 100%;
  padding: ${({ padding }) => padding};
  ${Media.lessThan(Media.small)} {
    background: url(${boxBgMb});
    background-size: 100%;
    width: ${mbSize(670)};
    height: ${mbSize(1054)};
    margin-top: ${mbSize(60)};
    padding: ${mbSize(100)} ${mbSize(36)} 0;
  }
`

export const BoxOl = styled.ol`
  padding: 0 0 0 16px;
  font-size: 16px;
  margin: 0 0 0;
  line-height: 36px;
  li {
    list-style: unset;
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(54)};
  }
`

export const SignUpButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 227px;
  height: 60px;
  background: url(${signUpBg});
  background-size: 100%;
  margin-top: 60px;
  span {
    font-size: 26px;
    font-family: YouSheBiaoTiHei;
    background: linear-gradient(274deg, #f0feea 0%, #febd2f 24%, #26fdf8 71%, #ffffff 104%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(340)};
    height: ${mbSize(90)};
    margin-top: ${mbSize(140)};
    span {
      font-size: ${mbSize(40)};
    }
  }
`

export const ExchangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    gap: ${mbSize(40)};
  }
`

export const QRCode = styled.img`
  width: 161px;
  height: 161px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(322)};
    height: ${mbSize(322)};
  }
`

export const ExchangeTextWrapper = styled.div`
  font-size: 16px;
  color: #ffffff;
  line-height: 36px;
  span {
    color: #fcbe2e;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(54)};
    text-align: center;
  }
`

export const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(54)};
    gap: ${mbSize(40)};
  }
`
