import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const Title = styled.img`
  width: 351px;
  height: 119px;
  margin-top: 120px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(514)};
    height: ${mbSize(188)};
    margin-top: ${mbSize(144)};
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    line-height: ${mbSize(60)};
    margin-top: ${mbSize(60)};
  }
`
