import React from 'react'
import { CommonTitle, CommonWrapper } from '../atoms'
import titleStandard from '../../../images/rc2025/title-standard.webp'
import { Content, Standard, StandardWrapper, Title } from './atoms'

const items = [
  { title: '技术应用', content: 'CG软件使用专业、熟练,模型、材质、渲染等制作质量优秀' },
  {
    title: '艺术美感',
    content:
      '画面表达流畅，视觉效果突出，整体设计具有美感和艺术价值，具有良好的传播观赏效果，有自己的风格亮点',
  },
  {
    title: '创意表现',
    content: '原创性和创意性：参赛作品是否具有独特的创意和新颖的表达方式，给人耳目一新的视觉感受',
  },
  { title: '契合主题', content: '对赛事主题概念想法的理解呈现，作品设计构思的故事性，剧情完整度' },
]

export const RC2025Standard = () => {
  return (
    <CommonWrapper>
      <CommonTitle src={titleStandard} alt='评选标准' />
      <StandardWrapper>
        {items.map((item) => (
          <Standard key={item.title}>
            <Title>{item.title}</Title>
            <Content>{item.content}</Content>
          </Standard>
        ))}
      </StandardWrapper>
    </CommonWrapper>
  )
}
