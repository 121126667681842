import React from 'react'

export const RightIcon = () => (
  <svg version='1.1' width='21' height='35' viewBox='0 0 21 35'>
    <defs>
      <clipPath id='master_svg0_81_21894'>
        <rect x='0' y='0' width='21' height='35' rx='0' />
      </clipPath>
    </defs>
    <g clipPath='url(#master_svg0_81_21894)'>
      <g transform='matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,2,-0.8284270763397217)'>
        <path
          d='M22.5605,4L22.5605,24.559L26.5605,24.559L26.5605,0L2,0L2,4L22.5605,4Z'
          fillRule='evenodd'
          fill='current'
          fillOpacity='1'
        />
      </g>
      <g transform='matrix(0.7071067690849304,0.7071067690849304,-0.7071067690849304,0.7071067690849304,10.363960921764374,0.9791849255561829)'>
        <path
          d='M10.64181,14L10.643550000000001,20.64284L12.64355,20.642319999999998L12.64129,12L4,12L4,14L10.64181,14Z'
          fillRule='evenodd'
          fill='current'
          fillOpacity='1'
        />
      </g>
    </g>
  </svg>
)
