import styled from 'styled-components'
import standardBg from '../../../images/rc2025/standard-bg.webp'
import { mbSize, Media } from '@renderbus/common/theme'

export const StandardWrapper = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 60px;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    margin-top: ${mbSize(60)};
    gap: ${mbSize(80)} ${mbSize(48)};
    justify-content: center;
  }
`

export const Standard = styled.div`
  width: 211px;
  height: 185px;
  background: url(${standardBg});
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(317)};
    height: ${mbSize(278)};
  }
`

export const Title = styled.div`
  font-family: YouSheBiaoTiHei;
  font-size: 26px;
  color: #ffffff;
  margin-top: 3px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(2)};
    font-size: ${mbSize(39)};
  }
`

export const Content = styled.div`
  width: 160px;
  font-size: 14px;
  color: #444444;
  margin-top: 20px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(240)};
    margin-top: ${mbSize(34)};
    font-size: ${mbSize(20)};
  }
`
