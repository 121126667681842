import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 1260px;
  z-index: 1;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 0;
    height: ${mbSize(1544)};
    .bg {
      display: none;
    }
  }
`

export const PartnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(60)};
  }
`

export const PartnerRole = styled.div`
  font-size: 20px;
  text-shadow: 0px 0px 29px #f8c12b;
  color: #fff6dc;
  margin-bottom: 8px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(24)};
    text-shadow: 0px 0px ${mbSize(32)} #f8c12b;
    margin-bottom: ${mbSize(8)};
  }
`

export const PartnerLine = styled.img`
  width: 106px;
  height: 7px;
  margin-bottom: 20px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(120)};
    height: ${mbSize(8)};
    margin-bottom: ${mbSize(20)};
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
  width: 1200px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(670)};
    gap: ${mbSize(40)} ${mbSize(28)};
    margin-bottom: ${mbSize(44)};
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 60px;
  background: rgba(112, 112, 112, 0.16);
  color: #ffffff;
  font-size: 14px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(204)};
    height: ${mbSize(68)};
    font-size: ${mbSize(16)};
  }
`

export const Logo = styled.img`
  width: ${({ width }) => width || '148px'};
  height: ${({ height }) => height || '36px'};
  ${Media.lessThan(Media.small)} {
    width: ${({ widthMb }) => mbSize(widthMb || 170)};
    height: ${({ heightMb }) => mbSize(heightMb || 40)};
  }
`
