import React from 'react'
import { CommonTitle } from '../atoms'
import titleContent from '../../../images/rc2025/title-content.webp'
import { Button, Content, ContentText, LeftContent, Video, VideoWrapper, Wrapper } from './atoms'
import { graphql, useStaticQuery } from 'gatsby'
import { Billboard } from '@renderbus/common/components'

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "rc2025/rc-content-bg.webp" }) {
      ...fluidImage
    }
    bgMb: file(relativePath: { eq: "rc2025/rc-content-bg-mb.webp" }) {
      ...fluidImage
    }
  }
`

export const RC2025Content = () => {
  const { bg, bgMb } = useStaticQuery(query)

  return (
    <Wrapper id='rc2025-content'>
      <CommonTitle src={titleContent} alt='大赛内容' marginTop='177px' />
      <Content>
        <LeftContent>
          <ContentText>
            <li>
              以大赛发放的3D动画模板场景为基础，进行创意制作，动画总计时长7秒整。角色和场景可以任意发挥，但是必须保证“角色”始末位置以及运动方向不变、相机运动轨迹不变；
            </li>
            <li>
              作品画面风格不限，允许三渲二、写实、卡通、漫画、水墨……渲染呈现出来的任何画风，都可接受；
            </li>
            <li>
              题材遵守“四不原则”：不违法，不三俗，不侵权，不惹事（不违反中国法律；不庸俗、低俗、媚俗；不侵犯他人合法权益；不涉及意识形态等争议话题）；
            </li>
          </ContentText>
          <Button>
            <a href='https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/04/%E5%A4%A7%E8%B5%9B%E8%B5%84%E6%96%99%E5%8C%85.rar'>
              大赛资料下载
            </a>
          </Button>
        </LeftContent>
        <VideoWrapper>
          <Video
            src='https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/04/a0001.mp4'
            controls
          />
        </VideoWrapper>
        <Billboard className='mb-bg' fluid={bgMb.childImageSharp.fluid} alt='bg-mb' />
      </Content>
      <Billboard className='pc-bg' fluid={bg.childImageSharp.fluid} alt='bg' />
    </Wrapper>
  )
}
