import { mbSize, Media } from '@renderbus/common/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  height: 60px;
  border-top: 1px solid rgba(112, 112, 112, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 14px;
  color: #8f8f8f;
  div {
    display: flex;
    align-items: center;
  }
  img {
    width: 14px;
    height: 16px;
    margin-right: 12px;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: end;
    align-items: center;
    width: ${mbSize(670)};
    height: ${mbSize(144)};
    font-size: ${mbSize(24)};
    gap: ${mbSize(16)};
    bottom: ${mbSize(60)};
    img {
      width: ${mbSize(28)};
      height: ${mbSize(32)};
      margin-right: ${mbSize(10)};
    }
  }
`
